import { useState, useEffect } from 'react';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../../hooks';
import { Quote } from '../../../../../../Typescript/backend/classes';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useMutation } from '@apollo/client';
import { VAGO_FORCE_RE_RATE } from '../../../../../../queries';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material';
import { useUsingVagoSettings } from '../../../../../../WhiteLabelSettings/VagoAssure/settings';

interface VagoForceReRateButtonProps {
  quote: Quote | null;
}
export const VagoForceReRateButton = ({
  quote,
}: VagoForceReRateButtonProps) => {
  const onVago = useUsingVagoSettings();
  const hasAdminPermissions = useAllowByPermission('is:admin');
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  if (!onVago || !quote || hasAdminPermissions === false) {
    return null;
  }
  return (
    <>
      <Button onClick={() => setOpen(true)}>Force Re-Rate</Button>
      <VagoForceReRateDialog
        open={open}
        quote={quote}
        onCancel={() => setOpen(false)}
        onComplete={() => {
          enqueueSnackbar('Successfully re-rated quote!', {
            variant: 'success',
          });
          setTimeout(() => setOpen(false), 1000);
        }}
      />
    </>
  );
};

interface VagoForceReRateDialopProps {
  quote: Quote;
  open: boolean;
  onCancel: () => void;
  onComplete: () => void;
}
const VagoForceReRateDialog = ({
  open,
  quote,
  onCancel,
  onComplete,
}: VagoForceReRateDialopProps) => {
  const [forceReRate, { loading, error, data }] = useMutation(
    VAGO_FORCE_RE_RATE,
    {
      refetchQueries: ['quoteInsurancePolicies'],
    },
  );
  const { token } = useAuth();
  useEffect(() => {
    if (data) {
      onComplete();
    }
  }, [data]);

  if (!token) {
    return null;
  }
  return (
    <Dialog open={open}>
      <DialogTitle>Force Re-Rate?</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure that you want to force a re-rate of this application?
          This will delete all existing policies on the application. This will
          also affect all subsequent file downloads and bordereaux. Please be
          very sure that you want to do this.
        </Typography>
        {data ? (
          <SuccessTypography>Successfully re-rated!</SuccessTypography>
        ) : null}
        {error ? <ErrorTypography>Error: {error}</ErrorTypography> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            forceReRate({
              variables: {
                token,
                uniqueId: quote.uniqueId,
              },
            });
          }}
        >
          Force Re-Rate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const ErrorTypography = styled(Typography)(({ theme }) => {
  return { color: theme.palette.error.main };
});

const SuccessTypography = styled(Typography)(({ theme }) => {
  return { color: theme.palette.success.main };
});
