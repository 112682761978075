import {
  Dispatch,
  SetStateAction,
  useEffect,
  // useEffect,
  useState,
} from 'react';
import { Quote } from '../../../../../Typescript/backend/classes';
import {
  Coverage,
  InsurancePolicy,
  Peril,
} from '../../../../../Typescript/classes';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  // InputAdornment,
  styled,
  // TextField
} from '@mui/material';
import {
  displayPerilPremium,
  displayPerilRate,
  displayPerilLimits,
} from './utility';
import { Checkbox, Form } from '@calefy-inc/informedMaterial';
import { useMutation } from '@apollo/client';
import { EDIT_PERILS } from '../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';
import { useSnackbar } from 'notistack';
import { EditPerilInput } from '../../../../../gql/graphql';
import Bugsnag from '@bugsnag/browser';
import {
  StyledTable,
  StyledTr,
  StyledTd,
  StyledTh,
  StyledTbody,
  StyledThead,
} from './PremiumOverridesTable';
import { PremiumOverrideConfirmationDialog } from './PremiumOverrideConfirmationDialog';
import { useDownloadVagoDocumentation } from '../../../../../hooks';
import { NumberInput } from '@calefy-inc/informedMaterial';

interface InsurancePolicyDisplayProps {
  quote: Quote;
  insurancePolicy: InsurancePolicy;
}
export const InsurancePolicyDisplay = ({
  quote,
  insurancePolicy,
}: InsurancePolicyDisplayProps) => {
  const [perilsToEdit, setPerilsToEdit] = useState<Array<EditPerilInput>>([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const { token } = useAuth();
  const [editPerils, { loading, error, data }] = useMutation(EDIT_PERILS, {
    refetchQueries: ['quoteInsurancePolicies'],
  });
  const { enqueueSnackbar } = useSnackbar();
  const downloadVagoDocumentation = useDownloadVagoDocumentation({
    uuid: quote.uniqueId,
    onSuccess: () =>
      enqueueSnackbar('Downloaded documents', { variant: 'success' }),
    onFailure: (error) => {
      enqueueSnackbar(`Error downloading documentation: ${error.message}`, {
        variant: 'error',
      });
    },
  });

  // display message on success & clear perils to edit
  useEffect(() => {
    if (data?.editPerils?.perils) {
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      setPerilsToEdit([]);
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      enqueueSnackbar('Error updating', { variant: 'error' });
      Bugsnag.notify(error);
    }
  }, [error]);

  // once the data is here and we're ready to download, do it
  useEffect(() => {
    if (data?.editPerils?.perils && downloadVagoDocumentation) {
      downloadVagoDocumentation();
    }
  }, [data, downloadVagoDocumentation]);
  return (
    <InsurancePolicyDisplayContainer>
      <Typography component='h2' variant='h4'>
        {insurancePolicy.description}
      </Typography>
      <LoadingButtonContainer>
        <LoadingButton
          variant='contained'
          loading={loading}
          disabled={!token || perilsToEdit.length === 0}
          onClick={() => {
            setConfirmationDialogOpen(true);
          }}
        >
          Save Changes
        </LoadingButton>
      </LoadingButtonContainer>
      {error ? (
        <ErrorSpan>Error updating premiums: {error.message}</ErrorSpan>
      ) : null}
      {insurancePolicy.coverages.map((coverage) => (
        <DisplayCoverage
          quote={quote}
          insurancePolicy={insurancePolicy}
          coverage={coverage}
          setPerilsToEdit={setPerilsToEdit}
          key={coverage.id}
        />
      ))}
      <PremiumOverrideConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          editPerils({
            variables: {
              token,
              perils: perilsToEdit,
            },
          });
        }}
        onCancel={() => {
          setConfirmationDialogOpen(false);
        }}
      />
    </InsurancePolicyDisplayContainer>
  );
};

const LoadingButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
});

const InsurancePolicyDisplayContainer = styled('section')({
  width: '100%',
});

interface DisplayCoverageProps {
  quote: Quote;
  insurancePolicy: InsurancePolicy;
  coverage: Coverage;
  setPerilsToEdit: Dispatch<SetStateAction<Array<EditPerilInput>>>;
}
const DisplayCoverage = ({
  // quote,
  // insurancePolicy,
  setPerilsToEdit,
  coverage,
}: DisplayCoverageProps) => {
  return (
    <DisplayCoverageContainer>
      <Typography component='h3' variant='h5' sx={{ alignSelf: 'start' }}>
        {coverage.getDisplayName()}
      </Typography>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            {['Peril', 'Limits', 'Rate', 'Premium', 'Override Premium?'].map(
              (header) => (
                <StyledTh scope='col' key={header}>
                  {header}
                </StyledTh>
              ),
            )}
          </StyledTr>
        </StyledThead>
        <StyledTbody>
          {coverage.perils.map((peril) => (
            <PerilRow peril={peril} updatePerils={setPerilsToEdit} />
          ))}
        </StyledTbody>
      </StyledTable>
    </DisplayCoverageContainer>
  );
};

const DisplayCoverageContainer = styled('section')(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginBottom: theme.spacing(3),
    '&:last-of-type': {
      marginBottom: theme.spacing(1),
    },
  };
});

interface PerilRowProps {
  peril: Peril;
  updatePerils: Dispatch<SetStateAction<Array<EditPerilInput>>>;
}
const PerilRow = ({ peril: originalPeril, updatePerils }: PerilRowProps) => {
  const [peril, setPeril] = useState<Peril>(originalPeril);
  const [isMatch, setIsMatch] = useState<boolean>(true);
  const { token } = useAuth();

  // whenever we update the peril, add it to the list to update
  useEffect(() => {
    if (peril.match(originalPeril)) {
      updatePerils((oldPerils) =>
        oldPerils.filter((oldperil) => oldperil.perilId !== peril.id),
      );
    } else {
      updatePerils((oldPerils) => [
        ...oldPerils.filter((oldperil) => oldperil.perilId !== peril.id),
        {
          perilId: peril.id,
          premium: String(peril.premium),
          overridePremium: peril.overridePremium,
        },
      ]);
    }
  }, [peril]);

  // check that the peril and original peril are the same
  useEffect(() => {
    setIsMatch(peril.match(originalPeril));
  }, [peril, originalPeril]);

  return (
    <StyledTr
      key={peril.id}
      sx={
        !isMatch
          ? {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }
          : {}
      }
    >
      <StyledTd>{peril.name}</StyledTd>
      <StyledTd>{displayPerilLimits(peril.limit)}</StyledTd>
      <StyledTd>
        {peril.overridePremium ? (
          <del>{displayPerilRate(peril.rate)}</del>
        ) : (
          displayPerilRate(peril.rate)
        )}
      </StyledTd>
      <StyledTd>
        {peril.overridePremium ? (
          <NumberInput
            field='premium'
            defaultValue={Math.round(peril.premium)}
            prefix='$'
            // @ts-expect-error
            onChange={(e) => {
              // first validate that it's a number
              let desiredPremium: string | number = e.target.value;
              if (desiredPremium === '') {
                desiredPremium = 0;
              }
              if (typeof desiredPremium === 'string') {
                // strip out the dollar sign and commas: $25,000 -> 25000
                desiredPremium = desiredPremium
                  .replace(/\$/g, '')
                  .replace(/,/g, '');
              }
              const premiumAsNumber = Number(desiredPremium);
              if (
                Number.isNaN(premiumAsNumber) ||
                !Number.isFinite(premiumAsNumber)
              ) {
                const message = `Unable to set premium for peril ${peril.id} - ${peril.name} to ${premiumAsNumber} (original: ${e.target.value}).`;
                console.error(message);
                Bugsnag.notify(new Error(message));
                return;
              }
              setPeril((oldPeril) =>
                oldPeril.copyWithAmendments({
                  premium: premiumAsNumber,
                }),
              );
            }}
          />
        ) : (
          displayPerilPremium(peril.premium)
        )}
      </StyledTd>
      <StyledTd>
        <Form
          initialValues={{
            overridePremium: peril.overridePremium,
          }}
        >
          <Checkbox
            field='overridePremium'
            disabled={!token || originalPeril.overridePremium}
            onChange={
              // @ts-expect-error
              (e) => {
                setPeril((oldPeril) => {
                  const checked = e.target.checked;
                  if (checked) {
                    return oldPeril.copyWithAmendments({
                      overridePremium: checked,
                    });
                  } else {
                    return originalPeril.copy();
                  }
                });
              }
            }
          />
        </Form>
      </StyledTd>
    </StyledTr>
  );
};

const ErrorSpan = styled('span')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});
