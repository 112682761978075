import { graphql } from '../gql';

export const GET_ALL_QUOTES = graphql(`
  query GetAllQuotes($token: String!, $search: String) {
    allQuotes(token: $token, search: $search) {
      id
      uniqueID
      dateAdded
      status
      businessLine {
        id
        displayName
      }
      structuredData {
        businessLegalName
        businessName
        businessTradeName
      }
    }
  }
`);
