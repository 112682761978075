// import { useQuery } from '@apollo/client';
// import { allBusinessTypes } from '../queries';
// types and classes
import type { RouteComponentProps } from '@reach/router';

import { InputToggle } from '@calefy-inc/informedMaterial';
// @ts-expect-error
import { Form, FormState } from 'informed';

export default function Testing(_props: RouteComponentProps) {
  return (
    <Form
      onSubmit={
        // @ts-expect-error
        (values) => {
          //console.log({ values });
        }
      }
    >
      <InputToggle
        options={[
          { value: 1, label: 'One' },
          { value: 2, label: 'Two' },
        ]}
        field='test'
        label='Testing'
        exclusive={false}
      />
      {/*
      // @ts-expect-error */}
      <FormState />
    </Form>
  );
}
