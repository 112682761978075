import { Select, TextField, Checkbox } from '@calefy-inc/informedMaterial';
// @ts-expect-error NB this is here because there is an error with the types of informed - it complains about not having an exported member 'Relevant'
import { ArrayField, Relevant } from 'informed';
import Typography from '@mui/material/Typography';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import RenderedQuestionInstance from '../RenderedQuestionInstance';
import { validateLogicQuestion } from './utility';
import { DisplayFormattedQuestionIdentifier } from '../common';
import { QuestionInstanceTreeDisplay } from '../QuestionInstanceTreeDisplay';

import { Component } from '../classes';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { QuoteComponentProps, ProgramBuilderComponentProps } from '../types';

const ProgramBuilderComponent = ({
  classes = {},
  questionInstance,
}: /* parentForm, */
ProgramBuilderComponentProps) => {
  /* // @ts-expect-error */
  /* const question_instances = parentForm.questionInstances || []; */
  const propsBlob =
    questionInstance && questionInstance.propsBlob
      ? questionInstance.propsBlob
      : {};
  const { not, operator, condition } = propsBlob;

  //Source component select drop down
  //"OnChange" we might want to define what the possible results are based on the component type (Yes/No)
  return (
    <>
      <Typography>
        First ask{' '}
        <DisplayFormattedQuestionIdentifier
          questionInstance={questionInstance?.subQuestions?.[0]}
          fallback='None (add this later)'
        />
        {' ,'} and if the answer is
      </Typography>
      <Checkbox
        classes={classes}
        className={classes.checkbox}
        field='props_blob_not'
        label='not'
        value={not}
      />
      <Select
        field='props_blob_operator'
        label='Operator'
        defaultValue={operator}
        required
        className={classes.selectField}
        classes={classes}
        options={[
          { label: 'equal to', value: 'equals' },
          { label: 'greater than', value: 'greater' },
          { label: 'less than', value: 'lesser' },
          { label: 'contains', value: 'contains' },
        ]}
        placeholder='Select the operation you want to perform'
        variant='standard'
      />
      <TextField
        field='props_blob_condition'
        value={condition}
        label='Conditional Value'
        placeholder='Conditional goes here'
        required
        classes={classes}
        className={classes.textField}
        variant='standard'
      />

      <Typography>then ask</Typography>
      <QuestionInstanceTreeDisplay
        questionInstances={
          questionInstance &&
          questionInstance.subQuestions &&
          questionInstance.subQuestions.length > 1
            ? questionInstance.subQuestions.slice(1)
            : undefined
        }
        fallback='None (add these later)'
      />
    </>
  );
};

const QuoteComponent = function ({
  questionInstance,
  classes = {},
}: QuoteComponentProps) {
  let source_question = questionInstance.subQuestions[0];
  const sub_questions = [...questionInstance.subQuestions].splice(1);
  const { not, operator, condition } = questionInstance.propsBlob;

  return (
    <>
      <ArrayField field={questionInstance.generateFieldName()}>
        {(arrayFieldApi) => {
          if (arrayFieldApi.fields.length === 0) {
            arrayFieldApi.add();
          }
          return (
            <>
              {/* @ts-expect-error */}
              <ArrayField.Items>
                {(arrayFieldItemsApi: $TSFixMe) => {
                  let arrayfield_source_question = source_question;
                  arrayfield_source_question.fieldPrefix =
                    arrayFieldItemsApi.field;
                  return (
                    <>
                      <RenderedQuestionInstance
                        questionInstance={arrayfield_source_question}
                        key={source_question.id}
                        classes={classes}
                      />
                      <Relevant
                        when={() => {
                          const source_value = arrayFieldItemsApi.getValue(
                            source_question.apiName,
                          );
                          let result = false;
                          if (source_value) {
                            switch (operator) {
                              case 'equals':
                                result = source_value === condition;
                                break;
                              case 'greater':
                                result = source_value >= condition;
                                break;
                              case 'lesser':
                                result = source_value <= condition;
                                break;
                              case 'contains':
                                result =
                                  typeof source_value === 'string' &&
                                  source_value.includes(condition);
                                break;
                            }
                            if (not) {
                              result = !result;
                            }
                          }
                          return result;
                        }}
                      >
                        {sub_questions.map((subQuestion) => {
                          subQuestion.fieldPrefix = arrayFieldItemsApi.field;
                          return (
                            <RenderedQuestionInstance
                              questionInstance={subQuestion}
                              key={subQuestion.id}
                              classes={classes}
                            />
                          );
                        })}
                      </Relevant>
                    </>
                  );
                }}
                {/* @ts-expect-error */}
              </ArrayField.Items>
            </>
          );
        }}
      </ArrayField>
    </>
  );
};

const IconComponent = () => {
  return <QuestionMarkRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'logic',
    typeLabel: 'Conditional Question',
    quoteComponent: QuoteComponent,
    dataType: 'nested',
    programBuilderComponent: ProgramBuilderComponent,
    programBuilderValidation: validateLogicQuestion,
    icon: IconComponent,
  });
}
export { QuoteComponent };
