export const SUPPORT_EMAIL = 'support@calefy.ca';

interface SupportLinkProps {
  subject?: string;
}
export const SupportLink = ({ subject }: SupportLinkProps) => {
  return (
    <a href={`mailto:${SUPPORT_EMAIL}` + (subject ? `subject=${subject}` : '')}>
      {SUPPORT_EMAIL}
    </a>
  );
};
