import { useState, Dispatch, SetStateAction } from 'react';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'; // material
import {
  Tooltip,
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useAuth } from '@calefy-inc/authentication';
import { LoadingIconButton } from './LoadingIconButton';
import { GenerateQuoteConfirmationButton } from './GenerateQuoteConfirmationButton';
import { SendRenewalEmailButton } from './SendRenewalEmailButton';
import { CopyRenewalLinkButton } from './CopyRenewalLinkButton';
import { VagoDownloadDocumentsButton } from './VagoDownloadDocumentsButton';
import { VagoForceReRateButton } from './VagoForceReRateButton';
import { SpireDocumentDownloadButton } from './SpireDocumentDownloadButton';
import { PythonDocxDocumentDownloadButton } from './PythonDocxDocumentDownloadButton';

// types and classes
import { Quote } from '../../../../../../Typescript/backend/classes';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------
interface QuoteToolbarProps {
  quote: Quote | null;
  setStatus: Dispatch<SetStateAction<Quote['status'] | null>>;
  onDownload: $TSFixMe;
  pdfStatus: $TSFixMe;
  selectedVersion: $TSFixMe;
  versionList: $TSFixMe;
  handleVersionChange: $TSFixMe;
  isArchived: $TSFixMe;
  handleRenewalOnlyChange: $TSFixMe;
  renewalOnly: $TSFixMe;
}

export default function QuoteToolbar({
  quote,
  setStatus,
  onDownload,
  pdfStatus,
  selectedVersion,
  versionList,
  handleVersionChange,
  isArchived,
  handleRenewalOnlyChange,
  renewalOnly,
}: QuoteToolbarProps) {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openPDF, setOpenPDF] = useState<boolean>(false);
  const { token } = useAuth();

  if (!quote) {
    return null;
  }
  if (!token) {
    return null;
  }

  return (
    <>
      <Stack
        mb={3}
        direction='row'
        justifyContent='space-between'
        alignItems='flex-end'
        spacing={1.5}
      >
        <Box sx={{ minWidth: 120, width: 250 }}>
          {versionList.length > 0 ? (
            <FormControl fullWidth size='small'>
              <InputLabel id='version-selector'>Versions</InputLabel>
              <Select
                labelId='version-selector-label'
                id='version-selector'
                value={selectedVersion}
                label='Versions'
                onChange={handleVersionChange}
              >
                <MenuItem value={'0'}>Current version</MenuItem>
                {versionList.map((version: $TSFixMe, index: $TSFixMe) => (
                  <MenuItem value={version.quoteId}>{`V${
                    versionList.length - index
                  } - ${version.dateAdded}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Box>
        <Stack direction='row' alignItems='center' spacing={1.5}>
          <PythonDocxDocumentDownloadButton
            quote={quote}
            renewalOnly={renewalOnly}
            selectedVersion={selectedVersion}
          />
          <SpireDocumentDownloadButton
            quote={quote}
            renewalOnly={renewalOnly}
            selectedVersion={selectedVersion}
          />
          <VagoForceReRateButton quote={quote} />
          <VagoDownloadDocumentsButton quote={quote} />
          <GenerateQuoteConfirmationButton quote={quote} />
          <CopyRenewalLinkButton
            uuid={quote.uniqueId}
            setStatus={setStatus}
            disabled={!(quote.isComplete() || quote.isReadyToRenew())}
          />
          {quote.status === 'RENEWED' ||
          quote.status === 'INCOMPLETE_RENEWAL' ||
          quote.status === 'RENEWAL_IN_PROGRESS' ? (
            <Tooltip
              title={
                !renewalOnly
                  ? 'Show renewal questions only'
                  : 'Show all questions '
              }
            >
              <IconButton
                size='small'
                aria-label='renewal questions only'
                onClick={handleRenewalOnlyChange}
              >
                {!renewalOnly ? (
                  <VisibilityRoundedIcon fontSize='small' />
                ) : (
                  <VisibilityOffRoundedIcon fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          ) : null}

          <Tooltip
            title={
              quote.isBound
                ? 'Quote has been bound; no further edits are possible'
                : quote.isComplete()
                ? 'Edit warning: Application has been completed'
                : 'Edit'
            }
          >
            <span>
              <IconButton
                aria-label={
                  quote.isBound
                    ? 'Quote has been bound; no further edits are possible'
                    : quote.isComplete()
                    ? 'Edit warning: Application has been completed'
                    : quote.isBound
                    ? 'Quote has been bound; no further edits are possible'
                    : 'Edit'
                }
                data-testid='edit_quote_button'
                size='small'
                href={`/insurtech/quote/resume/${quote.uniqueId}`}
                disabled={isArchived || quote.isBound}
              >
                <EditRoundedIcon fontSize='small' />
              </IconButton>
            </span>
          </Tooltip>

          <SendRenewalEmailButton
            quote={quote}
            setStatus={setStatus}
            isArchived={isArchived}
          />

          <Tooltip title='Download'>
            <LoadingIconButton
              size='small'
              aria-label='download'
              loading={pdfStatus}
              onClick={() => onDownload()}
              loadingPosition='center'
            >
              <DownloadRoundedIcon fontSize='small' />
            </LoadingIconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  );
}
