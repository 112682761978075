import { initializeStore } from './store';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import ClientProvider, { createClient } from './apollo';
import CssBaseline from '@mui/material/CssBaseline';
import { Router, Redirect } from '@reach/router';
import { QuoteWizard, QuoteResume } from './components/QuoteWizard';
import { BusinessLink } from './components/BusinessLink';
import { ReferralsLink } from './components/ReferralsLink';
import { ProducerIdLink } from './components/ProducerIdLink';
import { QuoteRenewal } from './components/QuoteWizard/QuoteRenewal';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {
  // Login,
  MandatoryLogout,
} from '@calefy-inc/authentication';
import Login from './components/Authentication/Login';
import { AuthProvider } from './components/Authentication';
import { QuotePdf } from './components/common/QuotePdf';
import { PremiumProposalsPdf } from './components/common/PremiumProposalsPdf';
import ManagementPanel from './components/ManagementPanel';
import { useDevStagingBackgrounds } from '@calefy-inc/hooks';
import Testing from './componentTester/Testing';
import { QuoteConfirm } from './components/QuoteWizard/QuoteConfirm';
import { Prospects } from './components/Prospects';
import { AdminPanel } from './components/AdminPanel/AdminPanel';
import { Iframe } from './components/Iframe';

import { Demo } from './components/Demo';
import { useSettings } from './hooks';

import NotistackProvider from './components/ManagementPanel/components/NotistackProvider';

const client = createClient();

//TODO eventually, it would be nice for the redux saga process to initialize the store
const store = initializeStore({ client });

// expose store when running in cypress
// @ts-expect-error
if (window.Cypress) {
  // @ts-expect-error
  window.store = store;
}
export type StoreState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

// const auth0Parameters = determineAuth0Parameters();

export default function App() {
  useDevStagingBackgrounds();
  TagManager.initialize({ gtmId: 'GTM-NSXJ4K7' });
  const { companyTheme, slug } = useSettings();

  return (
    <div id='app' style={{ minHeight: '100vh' }} data-whitelabel={slug}>
      <AuthProvider>
        {/* <Auth0Provider */}
        {/*   domain={auth0Parameters.domain} */}
        {/*   clientId={auth0Parameters.clientId} */}
        {/*   redirectUri={auth0Parameters.redirectUri} */}
        {/*   audience='https://backend/api' */}
        {/*   scope='access offline_access openid' */}
        {/*   useRefreshTokens={true} */}
        {/*   cacheLocation='localstorage' */}
        {/* > */}
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={companyTheme}>
            <CssBaseline />
            <Provider store={store}>
              <ClientProvider client={client}>
                <StyledEngineProvider injectFirst>
                  <NotistackProvider>
                    <Router>
                      <Redirect
                        from='core/getQuote/*'
                        to='/insurtech/quote'
                        noThrow
                      />
                      <Redirect
                        from='/insurtech/myQuote/:uniqueID'
                        to='/insurtech/manager/quotes/details/:uniqueID'
                        noThrow
                      />
                      <Demo path='/insurtech/demo/' />
                      <BusinessLink path='/insurtech/businessLink/:businessId' />
                      {
                        /* custom redirects for personal policies */
                        ['tenant', 'home', 'automobile', 'condo'].map(
                          (policy) => {
                            return (
                              <Redirect
                                key={policy}
                                from={`/insurtech/personal/${policy}`}
                                to={`/insurtech/businessLink/personal?policy=${policy}`}
                              />
                            );
                          },
                        )
                      }
                      {[
                        '/insurtech/referral/:referralCode',
                        '/insurtech/businessLink/:businessId/referral/:referralCode',
                      ].map((path) => (
                        <ReferralsLink path={path} key={path} />
                      ))}
                      {[
                        '/insurtech/producer/',
                        '/insurtech/producer/:producerId',
                      ].map((path) => {
                        return <ProducerIdLink path={path} key={path} />;
                      })}
                      <Testing path='testing/*' />
                      {[
                        '/insurtech/quote/*',
                        '/insurtech/quote/type/:directTo',
                      ].map((path) => (
                        <QuoteWizard path={path} key={path} />
                      ))}
                      <QuoteResume path='/insurtech/quote/resume/:uuid' />
                      <QuoteRenewal path='/insurtech/quote/renewal/:uuid' />
                      {/* 
                        // @ts-expect-error */}
                      <QuoteConfirm path='/insurtech/quote/confirm/:hash' />
                      <QuotePdf path='/insurtech/quotepdf/:hash' />
                      <PremiumProposalsPdf path='/insurtech/premiumproposalspdf/:hash/:insurerName' />
                      <ManagementPanel path='/insurtech/manager/*' />
                      {process.env.NODE_ENV === 'development' ? (
                        <Prospects
                          client={client}
                          path='/insurtech/prospecting'
                        />
                      ) : null}
                      <Iframe path='/insurtech/iframe/*' />
                      <AdminPanel path='/insurtech/admin/*' />
                      <Login exact path='/insurtech/login/*' />
                      <MandatoryLogout path='/insurtech/logout' />
                      <Redirect
                        from='/*'
                        to='/insurtech/quote'
                        default
                        noThrow
                      />
                    </Router>
                  </NotistackProvider>
                </StyledEngineProvider>
              </ClientProvider>
            </Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AuthProvider>
      {/* </Auth0Provider> */}
    </div>
  );
}
