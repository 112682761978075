import { v4 as createId } from 'uuid';
import { cloneDeep, isEqual } from 'lodash';

import { apportionFormValues } from './utility';

// types and classes
import type {
  $TSFixMe,
  GenericObject,
  OrNullish,
  BooleanWithUnknown,
} from '@calefy-inc/utilityTypes';
import { LanguageAwareString, Language } from '../../../../Typescript/classes';
import { QuestionInstanceInput } from '../../../../gql/graphql';
import { QuoteWizardQuestionInstance } from '../../../QuoteWizard/classes';
import { imposeLanguageOnPropsBlob } from '../../../../Typescript/classes/utility';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../../util';

/**
 * Ancillary options to control various aspects of ProgramBuilderQuestionInstance, but which are not inherently properties of the QuestionInstance itself.
 */
interface IOptions {
  editAll: boolean;
}
const defaultOptions: IOptions = {
  editAll: false,
};

interface ParentForms {
  id: string;
  businessLine: {
    id: string;
    displayName: string;
  };
  policy: null | {
    id: string;
    displayName: string;
  };
}
/**
 * Ancillary pieces of information
 */
interface IAncillary {
  form: $TSFixMe;
  parentForms: Array<ParentForms>;
  numParent?: number; // for the extensions
  parentId?: ProgramBuilderQuestionInstance['id']; // for the extensions
}
const defaultAncillary: IAncillary = {
  form: null,
  parentForms: [],
};

const defaultPropsBlob = {};

interface IGenerateFromValuesArgument {
  formValues: { [k: string]: unknown };
  languages: Array<Language>;
  oldQuestion?: OrNullish<ProgramBuilderQuestionInstance>;
  otherAttributes?: { [k: string]: $TSFixMe };
}

// TODO make it so that only one of clientId or id can be set
interface ProgramBuilderQuestionInstanceInput {
  id?: string | null;
  clientId?: string | null;
  apiName: string;
  displayNames: LanguageAwareString[];
  labels: LanguageAwareString[];
  helpTexts?: LanguageAwareString[];
  component: string;
  dataType: string;
  propsBlob?: {
    [k: string]: $TSFixMe;
  };
  required?: boolean;
  askOnRenewal?: boolean;
  prefillOnRenewal?: boolean;
  subQuestions?: Array<ProgramBuilderQuestionInstanceInput>;
  options?: Partial<IOptions>;
  ancillary?: Partial<IAncillary>;
  children?: Array<$TSFixMe>;
  expanded?: boolean;
  title?: string;
  maxParents?: number;
  node?: $TSFixMe;
  path?: $TSFixMe;
  modified?: BooleanWithUnknown; // is this a copy of an existing question for a FinalFormModifier? Can usually be ignored.
  defaultValue?: string;
}

/**
 * Class representing a QuestionInstance in the program builder
 */
export class ProgramBuilderQuestionInstance {
  id: string | null;
  clientId: string | null;
  apiName: string;
  displayNames: Array<LanguageAwareString>;
  labels: Array<LanguageAwareString>;
  helpTexts: Array<LanguageAwareString>;
  component: string;
  dataType: string;
  propsBlob: {
    [k: string]: $TSFixMe;
  };
  subQuestions: Array<ProgramBuilderQuestionInstance>;
  required: boolean;
  askOnRenewal: boolean;
  prefillOnRenewal: boolean;
  options: IOptions;
  ancillary: IAncillary;
  children: Array<$TSFixMe>;
  expanded?: boolean;
  title: string;
  maxParents: number;
  node: $TSFixMe;
  path: $TSFixMe;
  modified: BooleanWithUnknown;
  defaultValue: string;

  constructor({
    id,
    clientId,
    apiName,
    displayNames,
    labels,
    helpTexts,
    component,
    dataType,
    propsBlob,
    subQuestions,
    required,
    askOnRenewal,
    prefillOnRenewal,
    options,
    ancillary,
    children,
    expanded = true,
    title,
    maxParents,
    node,
    path,
    modified,
    defaultValue,
  }: ProgramBuilderQuestionInstanceInput) {
    // set the id or clientId, based on what was passed back
    if (!id && !clientId) {
      this.id = null;
      this.clientId = ProgramBuilderQuestionInstance.generateClientId();
    } else {
      this.id = id || null;
      this.clientId = clientId || null;
    }
    this.apiName = apiName;
    this.displayNames = displayNames.map((displayNameInput) =>
      LanguageAwareString.createFromObject(displayNameInput),
    );
    this.labels = labels.map((labelInput) =>
      LanguageAwareString.createFromObject(labelInput),
    );
    this.helpTexts = helpTexts
      ? helpTexts.map((helpText) =>
          LanguageAwareString.createFromObject(helpText),
        )
      : [];
    this.component = component;
    this.dataType = dataType;
    this.propsBlob = propsBlob ? propsBlob : {};
    this.subQuestions = subQuestions
      ? subQuestions.map((input) => new ProgramBuilderQuestionInstance(input))
      : [];
    this.required = required ? required : false;
    this.askOnRenewal = askOnRenewal ? askOnRenewal : false;
    this.prefillOnRenewal = prefillOnRenewal ? prefillOnRenewal : false;
    this.options = options ? { ...defaultOptions, ...options } : defaultOptions;
    this.ancillary = ancillary
      ? { ...defaultAncillary, ...ancillary }
      : defaultAncillary;
    this.children = children ? children : [];
    this.expanded = expanded === true ? true : false;
    this.title = title || '';
    this.maxParents = maxParents || 0;

    // sort out the children
    if (this.children.length === 0 && this.subQuestions.length !== 0) {
      this.children = this.subQuestions.map((subq) => subq.copy());
    }

    this.node = node;
    this.path = path;

    this.modified =
      modified === undefined || modified === 'unknown' ? 'unknown' : modified;
    this.defaultValue = defaultValue ? String(defaultValue) : '';
  }

  /**
   * Determine whether another ProgramBuilderQuestionInstance matches the current one, either by id or clientId
   */
  matchById(other: ProgramBuilderQuestionInstance) {
    const fieldsToCheck: Array<keyof ProgramBuilderQuestionInstance> = [
      'id',
      'clientId',
    ];
    for (let field of fieldsToCheck) {
      if (this[field] && other[field] && this[field] === other[field]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Convert into a formState from which the question can be recovered (basically - transform so it is compatible with the QuestionInstanceForm)
   */
  convertToFormState() {
    //console.log('About to convert', this, 'to formState');
    const excludedFields: Array<keyof ProgramBuilderQuestionInstance> = [
      'id',
      'clientId',
      'dataType',
      'subQuestions',
      'options',
      'ancillary',
      'children',
      'expanded',
      'modified',
    ];
    const languageAwareFields: Array<keyof ProgramBuilderQuestionInstance> = [
      'displayNames',
      'labels',
      'helpTexts',
    ];

    const initialFormState = Object.entries(this).reduce(
      (formValues: { [k: string]: string }, [k, v]) => {
        // @ts-expect-error
        if (excludedFields.includes(k)) {
          return formValues;
          // @ts-expect-error
        } else if (languageAwareFields.includes(k)) {
          const transformedValues = v.reduce(
            (
              transformedObj: { [k: string]: string },
              languageAwareString: LanguageAwareString,
            ) => {
              const transformedKey =
                languageAwareString.language.generatePrefixedString(k);
              const transformedValue = languageAwareString.value;
              transformedObj[transformedKey] = transformedValue;
              //console.log('transforming language-aware string', {
              //   k,
              //   languageAwareString,
              //   transformedObj,
              // });
              return transformedObj;
            },
            {},
          );

          formValues = {
            ...formValues,
            ...transformedValues,
          };
          return formValues;
        } else if (k === 'propsBlob') {
          const transformedObj = Object.entries(v).reduce(
            (transformed, [key, value]) => {
              if (key === 'options') {
                // @ts-expect-error
                transformed['props_blob_options'] = value.map(
                  (baseOption: $TSFixMe) => {
                    const newOption: GenericObject = {};
                    newOption.value = baseOption.value;
                    baseOption.labels.forEach((label: $TSFixMe) => {
                      newOption[
                        label.language.generatePrefixedString('labels')
                      ] = label.value;
                    });
                    return newOption;
                  },
                );
              } else {
                // @ts-expect-error
                transformed[`props_blob_${key}`] = value;
              }
              return transformed;
            },
            {},
          );
          return {
            ...formValues,
            ...transformedObj,
          };
        } else {
          formValues[k] = v;
          return formValues;
        }
      },
      {},
    );
    if (
      this.component === 'YesNoToggle' ||
      this.component === 'yearPicker' ||
      this.component === 'calendar'
    ) {
      initialFormState['default_boolean'] = this.defaultValue ? 'yes' : 'no';
    }
    return initialFormState;
  }

  /**
   * Generate a new ProgramBuilderQuestionInstance from a formState (and optionally) an originating question instance
   * @param formValues - informed formState.values
   * @param languages - List of known / relevant languages
   * @param oldQuestion - An original question instance from which some of the properties of the new one should be generated
   * @param otherAttributes - Additional attributes which map directly to ProgramBuilderQuestionInstance attributes
   */
  static generateFromValues({
    formValues,
    languages,
    oldQuestion,
    otherAttributes,
  }: IGenerateFromValuesArgument): ProgramBuilderQuestionInstance {
    // grab the initial values from the oldQuestion, if present
    let initialInputValues: $TSFixMe = otherAttributes ? otherAttributes : {};
    if (oldQuestion) {
      const attributesToCopy: Array<keyof ProgramBuilderQuestionInstance> = [
        'id',
        'clientId',
        'apiName',
        'subQuestions',
        'options',
        'expanded',
        'title',
        'ancillary',
        'dataType',
        'modified',
      ];
      initialInputValues = attributesToCopy.reduce((values, attribute) => {
        if (oldQuestion[attribute]) {
          // @ts-expect-error
          values[attribute] = cloneDeep(oldQuestion[attribute]);
        }
        return values;
      }, {});

      // sort out the ancillary values
      initialInputValues.ancillary = initialInputValues.ancillary || {};
      if (oldQuestion.ancillary.form) {
        initialInputValues.ancillary.form = oldQuestion.ancillary.form;
      }
      if (
        otherAttributes &&
        otherAttributes.ancillary &&
        otherAttributes.ancillary.form
      ) {
        initialInputValues.ancillary.form = otherAttributes.ancillary.form;
      } else if (otherAttributes) {
        Object.entries(otherAttributes).forEach(([k, v]) => {
          if (k !== 'ancillary') {
            initialInputValues[k] = cloneDeep(v);
          }
        });
      }
    }

    // now grab the updated values from the formValues
    const processed = apportionFormValues(formValues, languages);

    return ProgramBuilderQuestionInstance.createFromIncomplete({
      ...initialInputValues,
      ...processed,
    });
  }

  /**
   * Generate a single string by concatenating all of the names
   */
  generateDisplayName(separator: string = ' / ') {
    return this.displayNames.map((name) => name.value).join(separator);
  }

  /**
   * Generate a single string by concatenating all of the labels
   */
  generateLabel(separator: string = ' / ') {
    return this.labels.map((label) => label.value).join(separator);
  }

  /**
   * Create a ProgramBuilderQuestionInstance from an incomplete list of inputs; the missing attributes will be filled in with (bad but passable) defaults - e.g. an empty string or array
   */
  static createFromIncomplete(
    incompleteInput: Partial<ProgramBuilderQuestionInstanceInput>,
  ) {
    const defaults: ProgramBuilderQuestionInstanceInput = {
      apiName: '',
      displayNames: [],
      labels: [],
      helpTexts: [],
      component: '',
      dataType: '',
      propsBlob: defaultPropsBlob,
      subQuestions: [],
      required: false,
      askOnRenewal: false,
      prefillOnRenewal: false,
      options: defaultOptions,
      ancillary: defaultAncillary,
      children: [],
      expanded: true,
      modified: 'unknown',
    };

    const reconciledInput = {
      ...defaults,
      ...incompleteInput,
    };

    const newQuestion = new ProgramBuilderQuestionInstance(reconciledInput);

    return newQuestion;
  }

  /**
   * Converts a frontend ProgramBuilderQuestionInstance so that it can be sent to the backend
   */
  toQuestionInstanceInput(): QuestionInstanceInput {
    const input: QuestionInstanceInput = {
      apiName: this.apiName,
      displayNames: this.displayNames.map((name) =>
        name.toLanguageAwareStringInput(),
      ),
      labels: this.labels.map((label) => label.toLanguageAwareStringInput()),
      helpTexts: this.helpTexts.map((helpText) =>
        helpText.toLanguageAwareStringInput(),
      ),
      component: this.component,
      propsBlob: JSON.stringify(this.propsBlob),
      dataType: this.dataType,
      subQuestions: this.subQuestions.map((subQ) =>
        subQ.toQuestionInstanceInput(),
      ),
      required: this.required,
      askOnRenewal: this.askOnRenewal,
      prefillOnRenewal: this.prefillOnRenewal,
      modified: this.modified === 'unknown' ? undefined : this.modified,
      defaultValue: this.defaultValue,
    };

    input.pk = this.id ? this.id : this.clientId ? this.clientId : undefined;

    if (this.options.editAll) {
      input.editAll = true;
    }
    //console.log('in toQuestionInstanceInput - about to return', input);
    return input;
  }

  /**
   * Converts a QuestionInstanceType from the backend to a frontend ProgramBuilderQuestionInstance
   */
  static generateFromBackendResponse(response: $TSFixMe) {
    let propsBlob: GenericObject = defaultPropsBlob;
    if (response.propsBlob) {
      propsBlob = JSON.parse(response.propsBlob);
      //console.log('Parsed propsBlob', response.propsBlob, 'to', propsBlob);
    }
    if (propsBlob.options && propsBlob.options.length > 0) {
      try {
        propsBlob.options = propsBlob.options.map((option: $TSFixMe) => {
          //console.log('Parsing backend option', option);
          return {
            value: option.value,
            labels: option.labels.map(
              (label: $TSFixMe) =>
                new LanguageAwareString(
                  label.value,
                  new Language(
                    label.language.shortName,
                    label.language.fullName,
                  ),
                ),
            ),
          };
        });
      } catch (e) {
        Bugsnag.notify(errorify(e));
      }
    }
    const input: Partial<ProgramBuilderQuestionInstanceInput> = {
      ...response,
      propsBlob,
      ancillary: {
        ...defaultAncillary,
        parentForms: response.parentForms,
      },
      subQuestions: (response.subQuestions && response.subQuestions.length > 0
        ? response.subQuestions
        : []
      ).map((subqResponse: $TSFixMe) =>
        ProgramBuilderQuestionInstance.generateFromBackendResponse(
          subqResponse,
        ),
      ),
      modified: response.modified === null ? 'unknown' : response.modified,
    };

    return ProgramBuilderQuestionInstance.createFromIncomplete(input);
  }

  /**
   * Create a separate copy of this object
   */
  copy(): ProgramBuilderQuestionInstance {
    return new ProgramBuilderQuestionInstance({
      ...this,
      displayNames: this.displayNames.map((name) => name.copy()),
      labels: this.labels.map((label) => label.copy()),
      helpTexts: this.helpTexts.map((helpText) => helpText.copy()),
      subQuestions: this.subQuestions.map((subQ) => subQ.copy()),
      propsBlob: cloneDeep(this.propsBlob),
      options: cloneDeep(this.options),
      ancillary: cloneDeep(this.ancillary),
      children: this.children.map((child) => cloneDeep(child)),
      defaultValue: this.defaultValue,
    });
  }

  /**
   * Create a new question based off of this one, but with some amendments
   */
  copyWithAmendments(amendments: Partial<ProgramBuilderQuestionInstance>) {
    return new ProgramBuilderQuestionInstance({
      ...this.copy(),
      ...amendments,
    });
  }

  /**
   * Copy this question as a new one (so replace the id / clientId)
   */
  copyAsNew(): ProgramBuilderQuestionInstance {
    const copied = this.copy();
    copied.id = null;
    copied.clientId = ProgramBuilderQuestionInstance.generateClientId();
    copied.subQuestions = this.subQuestions.map((ele) => ele.copyAsNew());
    copied.children = [...copied.subQuestions];
    return copied;
  }

  /**
   * Get the list of the available languages for this questionInstance - that is, all of the languages for which this question has names and labels for that language, as do all of its subquestions
   */
  getAvailableLanguages() {
    // NB helpTexts is not included because that field is optional
    const languageAwareFields: Array<keyof ProgramBuilderQuestionInstance> = [
      'displayNames',
      'labels',
    ];
    let availableLanguages: Array<Language> = this[languageAwareFields[0]].map(
      (las: LanguageAwareString) => las.language,
    );

    // first check the fields of the question itself
    for (let field of languageAwareFields.slice(1)) {
      const otherLanguages = this[field].map(
        (las: LanguageAwareString) => las.language,
      );
      availableLanguages = Language.intersect(
        availableLanguages,
        otherLanguages,
      );
    }

    // now check the options in the propsBlob, if they exist
    if (this.propsBlob.options && this.propsBlob.options.length > 0) {
      const optionsLanguagesLists: Array<Array<Language>> =
        this.propsBlob.options.map((option: $TSFixMe) => {
          return option.labels.map((label: $TSFixMe) => label.language);
        });
      availableLanguages = Language.intersect(
        availableLanguages,
        ...optionsLanguagesLists,
      );
    }

    // now check the subquestions
    for (let subQ of this.subQuestions) {
      availableLanguages = Language.intersect(
        availableLanguages,
        subQ.getAvailableLanguages(),
      );
    }

    if (availableLanguages.length === 0) {
      console.error('Question', this, 'has no available languages');
    }
    return availableLanguages;
  }

  /**
   * Convert to a QuoteWizardQuestionInstance
   * @param language - The language to use for all language-aware fields
   */
  toQuoteWizardQuestionInstance(
    language: Language,
  ): QuoteWizardQuestionInstance {
    const directlyMappedFields: Array<keyof ProgramBuilderQuestionInstance> = [
      'id',
      'apiName',
      'component',
      'dataType',
      'required',
      'askOnRenewal',
      'prefillOnRenewal',
      'defaultValue',
    ];
    const input = {};
    for (let field of directlyMappedFields) {
      // @ts-expect-error
      input[field] = this[field];
    }

    // now the required language-aware fields
    const componentMapping: Array<
      [keyof ProgramBuilderQuestionInstance, keyof QuoteWizardQuestionInstance]
    > = [
      ['displayNames', 'displayName'],
      ['labels', 'label'],
    ];
    for (let [oldField, newField] of componentMapping) {
      const matched = this[oldField].find((las: LanguageAwareString) => {
        return language.equals(las.language);
      });
      if (!matched) {
        throw new Error(
          `Unable to find appropriate ${newField} for language ${JSON.stringify(
            language,
            null,
            4,
          )} in ${JSON.stringify(this, null, 4)}`,
        );
      }
      // @ts-expect-error
      input[newField] = matched.value;
    }

    // sort out the helpText
    const matchedHelpText = this.helpTexts.find((helpText) =>
      helpText.language.equals(language),
    );
    // @ts-expect-error
    input['helpText'] = matchedHelpText ? matchedHelpText.value : null;

    // now figure out the propsBlob - basically we need to be careful because of things like the inputtoggle having language-aware options
    // @ts-expect-error
    input.propsBlob = imposeLanguageOnPropsBlob(this.propsBlob, language);

    // now sort out the subquestions
    // @ts-expect-error
    input.subQuestions = this.subQuestions.map((subQ) =>
      subQ.toQuoteWizardQuestionInstance(language),
    );

    // @ts-expect-error
    return new QuoteWizardQuestionInstance(input);
  }

  /**
   * Determine whether this question is deeply equal to another one
   */
  equals(other: ProgramBuilderQuestionInstance) {
    if (!other) {
      return false;
    }
    const fieldsToDirectlyCompare: Array<keyof ProgramBuilderQuestionInstance> =
      [
        'id',
        'clientId',
        'apiName',
        'component',
        // 'dataType', // because honestly, we need to figure out what we are doing with this
        'required',
        'askOnRenewal',
        'prefillOnRenewal',
        'expanded',
        // 'title', // Breaks renewal on question instance form. We dont know what it really does yet
        'maxParents',
        'propsBlob',
        'options',
        // 'ancillary', // because I'm not sure how to compare these - the shape is not well defined
      ];
    if (
      !fieldsToDirectlyCompare.every((field) => {
        if (isEqual(this[field], other[field])) {
          return true;
        }

        return false;
      })
    ) {
      return false;
    }

    // now check the language-aware string fields - they should be the same length and have the same elements
    const languageAwareFields: Array<keyof ProgramBuilderQuestionInstance> = [
      'displayNames',
      'labels',
      'helpTexts',
    ];
    for (let languageAwareField of languageAwareFields) {
      if (
        this[languageAwareField].length !== other[languageAwareField].length
      ) {
        return false;
      }
      for (let las of this[languageAwareField]) {
        if (
          // @ts-expect-error
          !other[languageAwareField].some((otherLas) => las.equals(otherLas))
        ) {
          return false;
        }
      }
    }

    // Now check the subquestions
    if (this.subQuestions.length !== other.subQuestions.length) {
      return false;
    }
    for (let subq of this.subQuestions) {
      if (!other.subQuestions.some((otherSubq) => subq.equals(otherSubq))) {
        return false;
      }
    }
    return true;
  }

  /**
   * Update the `ancillary` field
   * @param newAncillary - An object with the fields to update in the `ancillary` field
   * @param combiningFn - A function which takes the current ancillary and the new one and combines them. Defaults to just a spread of the two
   */
  updateAncillary(
    newAncillary: Partial<IAncillary>,
    combiningFn: (
      oldAncillary: IAncillary,
      newAncillary: Partial<IAncillary>,
    ) => IAncillary = (
      oldAncillary: IAncillary,
      newAncillary: Partial<IAncillary>,
    ) => ({
      ...oldAncillary,
      ...newAncillary,
    }),
  ) {
    //console.log('in updateAncillary');
    return combiningFn(this.ancillary, newAncillary);
  }

  static generateClientId(): string {
    return createId();
  }
}
