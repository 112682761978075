import { useState, useEffect, useMemo } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
// hooks
import useSettings from '../../hooks/useSettings';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermissions } from '../../../../hooks';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { EnhancedTable } from '../../components/_dashboard/forms/list';
import Label from '../../components/Label';
// mui
import Tooltip from '@mui/material/Tooltip';
import { Button, Container, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { GET_ALL_FORMS } from '../../../../queries';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import LoadingScreen from '../../components/LoadingScreen';
import Bugsnag from '@bugsnag/js';
// pages
import ErrorPage from '../Page500';
import { InsufficientPermissionsErrorPage } from '../../../common/ErrorHandling';

// types and classes
import type { RouteComponentProps } from '@reach/router';

type FinalForm = {
  businessName: string;
  policyName: string;
  qualifyingQuestion: string;
  businessId: string;
  policyId: string | null;
  required: boolean;
};

interface FormListProps extends RouteComponentProps {}
/**
 * Provides features for viewing all the forms for the current organization
 *
 */
const FormList = (_props: FormListProps) => {
  const { themeStretch } = useSettings();
  const { token } = useAuth();
  const hasFormEditPermissions = useAllowByPermissions('edit:forms');
  const [formList, setFormList] = useState<FinalForm[]>([]);

  // Get all final forms
  const [getForms, { loading, data, error }] = useLazyQuery(GET_ALL_FORMS);

  // Column settings
  const columns = useMemo(
    () => [
      {
        Header: 'Business Type',
        accessor: 'business',
        width: 300,
        Cell: ({ value }: { value: string }) => (
          <Typography variant='subtitle2' noWrap>
            {value}
          </Typography>
        ),
      },
      {
        Header: 'Policy',
        accessor: 'policy',
      },
      {
        Header: 'Required',
        accessor: 'required',
        width: 100,
        Cell: ({ value }: { value: boolean }) =>
          value ? (
            <Label variant={'ghost'} color={'success'}>
              Required
            </Label>
          ) : (
            ''
          ),
      },
    ],
    [],
  );

  // Data
  const formsData = useMemo(() => [...formList], [formList]);

  useEffect(() => {
    if (token) {
      getForms({
        variables: {
          token,
        },
      });
    }
  }, [token, getForms]);

  useEffect(() => {
    if (data?.allFinalForms) {
      // New form object with data recieved
      const forms = data.allFinalForms.map((form) => ({
        // @ts-expect-error
        business: form.businessLine.displayName,
        // @ts-expect-error
        policy: form.policy ? form.policy.displayName : 'General Information',
        // @ts-expect-error
        businessId: form.businessLine.id,
        // @ts-expect-error
        policyId: form.policy ? form.policy.id : null,
        // @ts-expect-error
        required: form.required,
      }));

      // @ts-expect-error
      setFormList(forms);
    }
  }, [data]);

  if ((loading && formList.length === 0) || !token) {
    return <LoadingScreen />;
  }

  if (error) {
    Bugsnag.notify(error);
  }

  return (
    <Page title='Forms | Manager'>
      <Container maxWidth={themeStretch ? false : 'lg'} data-testid='forms'>
        {error && /invalid permissions/i.test(error.message) ? (
          <InsufficientPermissionsErrorPage deniedIdentifier='Form List' />
        ) : error ? (
          <ErrorPage />
        ) : (
          <>
            <HeaderBreadcrumbs
              heading='Form List'
              links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
                { name: 'Forms' },
              ]}
              action={
                <Tooltip
                  title={
                    hasFormEditPermissions
                      ? 'New Form'
                      : "You don't have permission to create a new form"
                  }
                >
                  <span>
                    <Button
                      variant='contained'
                      component={RouterLink}
                      to={'/insurtech/manager/forms/create'}
                      startIcon={<Icon icon={plusFill} />}
                      disabled={!hasFormEditPermissions}
                    >
                      New Form
                    </Button>
                  </span>
                </Tooltip>
              }
            />

            <EnhancedTable
              columns={columns}
              data={formsData}
              getRowProps={(row: $TSFixMe) => {
                // Row needs to have all of these in order to navigate
                if (
                  !row.business ||
                  !row.policy ||
                  !row.businessId ||
                  !hasFormEditPermissions
                ) {
                  return {};
                } else {
                  return {
                    component: RouterLink,
                    to: `/insurtech/manager/forms/edit/${row.businessId}/${
                      row.policyId ? row.policyId : 0
                    }`,
                    sx: { textDecoration: 'none' },
                  };
                }
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
};

export default FormList;
