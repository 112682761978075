import { Peril } from '../../../../../Typescript/classes';

export const displayPerilRate = (rate: Peril['rate']) => {
  if (typeof rate !== 'number') {
    return rate;
  }
  const formatted = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 4,
    style: 'percent',
  }).format(rate);
  return formatted;
};

export const displayPerilLimits = (limit: Peril['limit']) => {
  if (limit === undefined || limit === null) {
    return '';
  }
  if (typeof limit !== 'number') {
    return limit;
  }
  return `$${limit.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })}`;
};

/**
 * Display the premium, including the dollar sign, appropriate number of decimal places, &c.
 */
export const displayPerilPremium = (premium: Peril['premium']) => {
  if (premium === undefined || premium === null) {
    return '';
  }
  if (typeof premium !== 'number') {
    return premium;
  }
  return `$${premium.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })}`;
};
