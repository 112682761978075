import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import saveFill from '@iconify/icons-eva/save-fill';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { ProgramBuilderWizard } from '../../../../store';
import { validateForm } from './validateForm';
import { ErrorDialog } from '../../util/ErrorDialog';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderForm } from '../../classes';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../../util';

interface UpdateButtonProps {
  form: ProgramBuilderForm;
}
const UpdateButton = ({ form }: UpdateButtonProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { requestPublishForm, requestUpdateForm } =
    ProgramBuilderWizard.actions;
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

  const handleErrorClose = (_event: $TSFixMe, reason: $TSFixMe) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessages([]);
  };

  return (
    <>
      <LoadingButton
        loading={loading}
        loadingPosition='end'
        size='small'
        endIcon={<Icon icon={saveFill} />}
        variant='contained'
        disabled={form.questionInstances.length === 0}
        onClick={() => {
          const formErrors = validateForm(form);
          setErrorMessages(formErrors);
          if (formErrors.length === 0) {
            setLoading(true);
            if (form.new) {
              new Promise((resolve, reject) => {
                dispatch(
                  requestPublishForm({
                    form: form,
                    questionInstances: form.questionInstances,
                    onSuccessCallback: resolve,
                    onFailCallback: reject,
                  }),
                );
              })
                .then(
                  (_response: $TSFixMe) => {
                    setTimeout(function () {
                      setLoading(false);
                      enqueueSnackbar('Form Created', {
                        variant: 'success',
                      });
                    }, 1000);
                  },
                  (_reason: $TSFixMe) => {
                    setTimeout(function () {
                      setLoading(false);
                      enqueueSnackbar('Creation Failed', {
                        variant: 'error',
                      });
                    }, 1000);
                  },
                )
                .catch((err) => {
                  console.error('Error creating new form', err);
                  Bugsnag.notify(errorify(err));
                });

              return;
            }

            new Promise((resolve, reject) => {
              dispatch(
                requestUpdateForm({
                  form: form,
                  questionInstances: form.questionInstances,
                  // @ts-expect-error
                  onSuccessCallback: resolve,
                  onFailCallback: reject,
                }),
              );
            })
              .then(
                (_response: $TSFixMe) => {
                  setTimeout(function () {
                    setLoading(false);
                    enqueueSnackbar('Form Updated', {
                      variant: 'success',
                    });
                  }, 1000);
                },
                (_reason: $TSFixMe) => {
                  setTimeout(function () {
                    setLoading(false);
                    enqueueSnackbar('Update Failed', {
                      variant: 'error',
                    });
                  }, 1000);
                },
              )
              .catch((err) => {
                console.error('Error updating form', err);
                Bugsnag.notify(errorify(err));
              });
          } else {
            console.error('Form Error Detected');
            Bugsnag.notify(
              new Error(`FormError in UpdateButton: ${formErrors}`),
            );
          }
        }}
      >
        {form.new ? 'Create Form' : 'Update Form'}
      </LoadingButton>
      <ErrorDialog
        messages={errorMessages}
        open={errorMessages.length > 0}
        onClose={handleErrorClose}
      />
    </>
  );
};

export default UpdateButton;
