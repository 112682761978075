import { cloneDeep } from 'lodash';
import { v4 as createId } from 'uuid';

import { ProgramBuilderQuestionInstance } from '..';
import type { $TSFixMe, OrNullish } from '@calefy-inc/utilityTypes';
import { BusinessType, Policy, WithId } from '../../../../Typescript';
import { Language } from '../../../../Typescript/classes';
import { QuoteWizardForm } from '../../../QuoteWizard/classes';
import type { PolicySummary } from '../../../QuoteWizard/types';
import { getAvailableLanguagesFromQuestionInstanceList } from './utility';

// This is getting perilously close to having enough behaviour to spin off into its own class - see the copy function, for example
interface PolicyExtension {
  id?: string; // this may come back from the backend, but we generally don't care about it
  label: string;
  questionInstanceId?: string;
  value: number;
  multiplier: number;
}

interface IProgramBuilderFormConstructorArgument {
  id?: OrNullish<string>;
  clientId?: OrNullish<string>;
  policy?: OrNullish<Policy>;
  new: boolean;
  name: string;
  businessLine: WithId;
  removed: boolean;
  businessType: BusinessType;
  questionInstances: Array<ProgramBuilderQuestionInstance>;
  qualifyingQuestion?: ProgramBuilderQuestionInstance | null;
  required: boolean;
  policyExtensions?: OrNullish<Array<PolicyExtension>>;
  [k: string]: $TSFixMe;
}

export class ProgramBuilderForm {
  id: string | null;
  clientId: string | null;
  policy: Policy | null;
  new: boolean;
  name: string;
  businessLine: $TSFixMe;
  removed: boolean;
  businessType: BusinessType;
  questionInstances: Array<ProgramBuilderQuestionInstance>;
  qualifyingQuestion: ProgramBuilderQuestionInstance | null;
  required: boolean;
  policyExtensions: Array<PolicyExtension> | null; // should be null on General Information forms and a (possibly empty) array otherwise
  [k: string]: $TSFixMe;

  constructor({
    id,
    clientId,
    policy,
    new: isNew,
    name,
    businessLine,
    removed,
    businessType,
    questionInstances,
    qualifyingQuestion,
    required,
    policyExtensions,
    ...rest
  }: IProgramBuilderFormConstructorArgument) {
    // sort out the id / clientId
    if (!id && !clientId) {
      this.id = null;
      this.clientId = ProgramBuilderForm.generateClientId();
    } else {
      this.id = id || null;
      this.clientId = clientId || null;
    }

    this.policy = policy ? policy : null;
    this.new = isNew;
    this.name = name;
    if (!businessLine) {
      this.businessLine = businessType;
      this.businessType = businessType;
    } else if (!businessType) {
      this.businessLine = businessLine;
      // @ts-expect-error
      this.businessType = businessLine;
    } else {
      this.businessLine = businessLine;
      this.businessType = businessType;
    }
    this.removed = removed;
    this.questionInstances = questionInstances;
    this.qualifyingQuestion = qualifyingQuestion || null;
    this.required = required;
    this.policyExtensions = !policy
      ? null
      : policyExtensions
      ? policyExtensions
      : [];

    Object.entries(rest).forEach(([k, v]) => (this[k] = v));
  }

  matchById(other: ProgramBuilderForm) {
    const fieldsToCheck: Array<keyof ProgramBuilderForm> = ['id', 'clientId'];
    return fieldsToCheck.some(
      (field) => this[field] && other[field] && this[field] === other[field],
    );
  }

  /**
   * Return a copy of the ProgramBuilderForm
   */
  copy() {
    return new ProgramBuilderForm({
      ...this,
      policy: cloneDeep(this.policy),
      businessLine: cloneDeep(this.businessLine),
      businessType: cloneDeep(this.businessType),
      questionInstances: this.questionInstances.map((q) => q.copy()),
      policyExtensions:
        this.policyExtensions === null
          ? null
          : this.policyExtensions.map((policyExtension) => {
              const { id, ...toCopy } = policyExtension;
              return toCopy;
            }),
    });
  }

  /**
   * Returns a new Form based on this one, but with amendments
   */
  copyWithAmendments(amendments: Partial<ProgramBuilderForm>) {
    return new ProgramBuilderForm({
      ...this.copy(),
      ...amendments,
    });
  }

  /**
   * Convert a ProgramBuilderForm so that it can be sent to the backend
   */
  toBackendForm() {}

  /**
   * Get a list of the available languages for this form - that is, a list of languages for which each questionInstance has names / labels in those languages
   */
  getAvailableLanguages() {
    const questionsList: Array<ProgramBuilderQuestionInstance> = [
      ...this.questionInstances,
    ];
    if (this.qualifyingQuestion) {
      questionsList.push(this.qualifyingQuestion);
    }
    return getAvailableLanguagesFromQuestionInstanceList(questionsList);
  }

  /**
   * Convert to a QuoteWizardForm
   * @param language - The language to use for all language-aware fields
   */
  toQuoteWizardForm(language: Language): QuoteWizardForm {
    const input = {
      ...this,
      qualifyingQuestion: this.qualifyingQuestion
        ? this.qualifyingQuestion.toQuoteWizardQuestionInstance(language)
        : null,
      questionInstances: this.questionInstances.map((q) =>
        q.toQuoteWizardQuestionInstance(language),
      ),
    };
    if (input.id === null) {
      throw new Error(
        `Attempting to create a QuoteWizardForm from ${JSON.stringify(
          this,
          null,
          4,
        )}, but missing the required "id" attribute`,
      );
    }
    // @ts-expect-error
    return new QuoteWizardForm(input);
  }

  /**
   * Get the display name for the form (in the form businessLine - Policy)
   */
  getDisplayName() {
    return `${this.businessLine.displayName} - ${
      this.policy ? this.policy.displayName : 'General Information'
    }`;
  }

  /**
   * Take a FormType from the backend and transform it into a frontend ProgramBuilderForm
   */
  static generateFromBackendResponse(response: $TSFixMe): ProgramBuilderForm {
    const defaults = {
      new: false,
      removed: false,
      questionInstances: [],
      qualifyingQuestion: null,
      required: false,
      policyExtensions: [],
    };

    const newForm = new ProgramBuilderForm({
      ...defaults,
      ...response,
      qualifyingQuestion: response.qualifyingQuestion
        ? ProgramBuilderQuestionInstance.generateFromBackendResponse(
            response.qualifyingQuestion,
          )
        : null,
      questionInstances: response.questionInstances
        ? response.questionInstances.map((rawQuestionInstance: $TSFixMe) =>
            ProgramBuilderQuestionInstance.generateFromBackendResponse(
              rawQuestionInstance,
            ),
          )
        : [],
      policyExtensions: response.policyExtension
        ? response.policyExtension
        : [],
    });
    //console.log(
    //   'in generateFromBackendResponse - just created',
    //   newForm,
    //   'from',
    //   response,
    // );
    return newForm;
  }

  /**
   * Return the policy summary for this form
   */
  generatePolicySummary(): PolicySummary {
    if (!this.policy) {
      throw new Error(
        `Error: attempting to get policy summary for a general business information form: ${JSON.stringify(
          this,
          null,
          4,
        )}`,
      );
    }
    return {
      id: this.policy.id,
      displayName: this.policy.displayName,
      // @ts-expect-error
      internalName: this.policy.internalName,
      qualifyingQuestion: this.qualifyingQuestion,
      required: this.required,
      availableLanguages: this.getAvailableLanguages(),
    };
  }

  /**
   * Generate the clientId (but don't assign it)
   */
  static generateClientId() {
    return createId();
  }
}
