import { graphql } from '../../gql';

export const TOGGLE_BIND_STATUS = graphql(`
  mutation toggleBindStatus($token: String!, $uniqueId: String!) {
    toggleBindStatus(token: $token, uniqueId: $uniqueId) {
      quote {
        id
        isBound
      }
    }
  }
`);
