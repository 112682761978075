import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { QuoteWizardState } from '../../../store';
import { GET_QUOTE, SOME_FINAL_FORMS } from '../../../queries';
import { parseData, baseHandleSpecificErrors } from './utility';
import { setRenewalInProgress } from '../../../store/QuoteWizardState';
import LoadingScreen from '../../ManagementPanel/components/LoadingScreen';

import {
  QuoteCompletedError,
  QuoteMarkedAsBoundError,
} from './ErrorComponents';

// for general error handling
import { handleErrors } from '../../common/ErrorHandling';

// for throwing the user to the business selection once everything's good
import { Redirect } from '@reach/router';

// functionality: allow logged-in users to edit complete quotes, but not when not logged in
import { useAuth } from '@calefy-inc/authentication';
import {
  createApplicationResumedThunk,
  createFormLoadedThunk,
} from '../../../store/analyticsStore';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { BusinessType } from '../../../Typescript';
import { PolicySummary } from '../types';
import { QuoteWizardForm, QuoteWizardAnswerInstance } from '../classes';
import type { ParsedAnswers } from './utility';
import type { RouteComponentProps } from '@reach/router';
import type { Dispatch, StoreState } from '../../../App';
import type { QuoteStatus } from '../../../Typescript/backend/types';

interface Phases {
  quote: boolean;
  confirming: boolean;
  business: boolean;
  policies: boolean;
  answers: boolean;
  uniqueID: boolean;
  formsLoaded: boolean;
  renewalInProgress: boolean;
  producerId: boolean;
}

interface UnthemedQuoteResumeProps
  extends RouteComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  uuid?: string;
}
const UnthemedQuoteResume = ({
  uuid,
  selectBusinessType,
  setPolicies,
  submitAnswersForForm,
  setCurrentQuoteUUID,
  formsLoaded,
  setAdditionalInformation,
  setConfirmationInProgress,
  setResumeToStep,
  setProducerId,
}: UnthemedQuoteResumeProps) => {
  /*console.log('In QuoteResume'); */
  const dispatch = useDispatch();
  const [businessId, setBusinessId] = useState<string | null>(null); // id for the business line in the quote

  const [businessLine, setBusinessLine] = useState<BusinessType>();
  const [selectedPolicies, setSelectedPolicies] = useState<
    Array<PolicySummary>
  >([]);
  const [businessForm, setBusinessForm] = useState<QuoteWizardForm | null>();
  const [policyForms, setPolicyForms] = useState<Array<QuoteWizardForm>>();
  const [parsedAnswers, setParsedAnswers] = useState<Array<ParsedAnswers>>([]);

  const { token } = useAuth();

  // which things we have accomplished
  const [completedPhases, setCompletedPhases] = useState<Phases>({
    quote: false,
    confirming: false,
    business: false,
    policies: false,
    answers: false,
    uniqueID: false,
    formsLoaded: false,
    renewalInProgress: false,
    producerId: false,
  });
  // function to set the complete phase
  function setPhaseCompleted(phase: keyof Phases) {
    setCompletedPhases((oldCompletedPhases) => ({
      ...oldCompletedPhases,
      [phase]: true,
    }));
  }
  const [quoteStatus, setQuoteStatus] = useState<QuoteStatus | null>(null); // the status of the quote - used for controlling when e.g. renewing

  //retrieve the partially completed (hopefully) quote
  const [getQuote, quoteQueryStatus] = useLazyQuery(GET_QUOTE, {
    // @ts-expect-error
    variables: { uID: uuid, token },
    onCompleted: (data) => {
      // check if it's bound, in which case we abort the whole process
      if (data.quote?.isBound) {
        return;
      }
      /*console.log('Loaded quote in QuoteResume:', data); */
      // @ts-expect-error
      setBusinessId(data.quote.businessLine.id);
      setPhaseCompleted('quote');
      // @ts-expect-error
      const quoteStatus = data.quote.QuoteStatus as QuoteStatus;
      setQuoteStatus(quoteStatus);

      // sort out renewal
      const renewalInProgress =
        quoteStatus === 'RENEWAL_IN_PROGRESS' ||
        quoteStatus === 'INCOMPLETE_RENEWAL';
      dispatch(setRenewalInProgress(renewalInProgress));
      setPhaseCompleted('renewalInProgress');
      setAdditionalInformation(
        // @ts-expect-error
        !renewalInProgress ? data.quote.additionalInformation : '',
      );

      // sort out confirmation
      const confirmationInProgress =
        quoteStatus === 'CONFIRMATION_IN_PROGRESS' ||
        quoteStatus === 'INCOMPLETE_CONFIRMATION';

      if (confirmationInProgress) {
        setConfirmationInProgress(true);
      }
      setPhaseCompleted('confirming');

      if (quoteStatus === 'CONFIRMATION_IN_PROGRESS') {
        setResumeToStep('review');
      } else {
        setResumeToStep('General Information');
      }
      setProducerId(data.quote?.producerId || '');
      setPhaseCompleted('producerId');
    },
  });

  // once we have the quote, get the relevant forms
  const [getBusinessForms, businessFormsQueryStatus] =
    useLazyQuery(SOME_FINAL_FORMS);

  // get the initial quote data
  useEffect(() => {
    if (token || token === null) {
      getQuote();
    }
  }, [getQuote, token]);

  // get the business form
  useEffect(() => {
    //console.log(`In business form useEffect`);
    if (businessId) {
      getBusinessForms({
        variables: {
          businessId,
        },
      });
    }
  }, [businessId, getBusinessForms]);

  // get the uniqueID from the quote and set it in the store state
  // (we technically have it from the URL, but getting it from the query means that we get some error checking - it exists, form isn't complete, &c.)
  useEffect(() => {
    if (!quoteQueryStatus.data || !quoteQueryStatus.data.quote) {
      return;
    }
    const uniqueID = quoteQueryStatus.data.quote.uniqueID;
    setCurrentQuoteUUID(uniqueID ? uniqueID : null);
    setPhaseCompleted('uniqueID');
  }, [quoteQueryStatus, setCurrentQuoteUUID, setCompletedPhases]);

  // parse the form + quote data and fire off the dispatches to change things in the store
  useEffect(() => {
    if (!quoteQueryStatus.data || !businessFormsQueryStatus.data) {
      return;
    }
    if (!quoteStatus) {
      return;
    }

    const {
      businessLine: parsedBusinessLine,
      selectedPolicies: parsedSelectedPolicies,
      businessForm: parsedBusinessForm,
      policyForms: parsedPolicyForms,
      parsedAnswers: parsedParsedAnswers,
    } = parseData(
      quoteQueryStatus.data,
      businessFormsQueryStatus.data,
      quoteStatus,
    );

    setBusinessLine(parsedBusinessLine);
    setSelectedPolicies(parsedSelectedPolicies);
    setBusinessForm(parsedBusinessForm);
    setPolicyForms(parsedPolicyForms);
    setParsedAnswers(parsedParsedAnswers);
  }, [quoteQueryStatus.data, businessFormsQueryStatus.data, quoteStatus]);

  // for setting the business type - once we have this notify the backend we have resumed the application
  useEffect(() => {
    if (businessLine) {
      selectBusinessType(businessLine);
      setPhaseCompleted('business');
      dispatch(createApplicationResumedThunk());
    }
  }, [businessLine, selectBusinessType]);

  // for setting the selected policies, business information form, and all policy forms
  useEffect(() => {
    if (selectedPolicies && businessForm && policyForms) {
      setPolicies(selectedPolicies, businessForm, policyForms);
      setPhaseCompleted('policies');
      [businessForm, ...policyForms]
        .filter((form) => Object.keys(form).length > 0)
        .forEach((form) => dispatch(createFormLoadedThunk(form)));
    }
  }, [selectedPolicies, businessForm, policyForms, setPolicies]);

  // for setting the answers
  useEffect(() => {
    if (parsedAnswers) {
      parsedAnswers.forEach((answerPair: $TSFixMe) => {
        submitAnswersForForm(answerPair.form, answerPair.answers);
      });
      setPhaseCompleted('answers');
    }
  }, [parsedAnswers, submitAnswersForForm]);

  useEffect(() => {
    if (formsLoaded) {
      setPhaseCompleted('formsLoaded');
    }
  }, [formsLoaded]);

  if (!uuid) {
    return null;
  }

  // currying the handleErrors function
  const handleSpecificErrors = ((uuid: string) => (error: $TSFixMe) => {
    if (
      error.message.match(
        /attempting to access a completed quote without a valid token. permission denied/i,
      )
    ) {
      return <QuoteCompletedError uuid={uuid} />;
    } else if (error.message.match(/marked as bound/i)) {
      return <QuoteMarkedAsBoundError uuid={uuid} />;
    }
    return baseHandleSpecificErrors(error, uuid);
  })(uuid);

  if (quoteQueryStatus.error) {
    // THIS IS WHAT WE NEED TO USE!
    return handleErrors(quoteQueryStatus.error, handleSpecificErrors);
  }
  if (businessFormsQueryStatus.error) {
    return handleErrors(businessFormsQueryStatus.error, handleSpecificErrors);
  }
  if (quoteQueryStatus.data?.quote?.isBound) {
    return (
      <QuoteMarkedAsBoundError uuid={quoteQueryStatus.data.quote.uniqueID} />
    );
  }

  // if any of the queries are still processing OR we have not yet completed all of the bookkeeping / processing of the request nonsense, display the loading screen
  if (
    !quoteQueryStatus.called ||
    quoteQueryStatus.loading ||
    !businessFormsQueryStatus.called ||
    businessFormsQueryStatus.loading ||
    Object.values(completedPhases).includes(false)
  ) {
    return <LoadingScreen />;
  }

  // once we're all good redirect to the quote wizard
  return (
    <Redirect
      to={!token ? '/insurtech/quote/' : '/insurtech/manager/quotes/create'}
      noThrow
    />
  );
};

/* Connect to the store */

const mapStateToProps = (state: StoreState) => {
  return {
    formsLoaded:
      Object.keys(state.quoteWizard.businessForm).length > 0 &&
      state.quoteWizard.policyForms.length > 0,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const {
    selectBusinessType,
    setPolicies,
    submitAnswersForForm,
    setCurrentQuoteUUID,
    setAdditionalInformation,
    setConfirmationInProgress,
    setResumeToStep,
    setProducerId,
  } = QuoteWizardState.actions;

  return {
    selectBusinessType: (selectedBusinessType: BusinessType) => {
      dispatch(selectBusinessType({ selectedBusinessType }));
    },
    setPolicies: (
      selectedPolicies: Array<PolicySummary>,
      businessForm: QuoteWizardForm,
      policyForms: Array<QuoteWizardForm>,
    ) => {
      dispatch(setPolicies({ selectedPolicies, businessForm, policyForms }));
    },
    submitAnswersForForm: (
      form: QuoteWizardForm,
      answers: Array<QuoteWizardAnswerInstance>,
    ) => {
      dispatch(submitAnswersForForm({ form, answers }));
    },
    setCurrentQuoteUUID: (uuid: string) => {
      dispatch(setCurrentQuoteUUID({ uuid }));
    },
    setAdditionalInformation: (additionalInformation: string) => {
      dispatch(setAdditionalInformation(additionalInformation));
    },
    setConfirmationInProgress: (inProgress: boolean) => {
      dispatch(setConfirmationInProgress(inProgress));
    },
    setResumeToStep: (
      resumeToStep: StoreState['quoteWizard']['resumeToStep'],
    ) => {
      dispatch(setResumeToStep(resumeToStep));
    },
    setProducerId: (producerId: StoreState['quoteWizard']['producerId']) => {
      dispatch(setProducerId(producerId));
    },
  };
};

const ConnectedQuoteResume = connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-expect-error
)((props: $TSFixMe) => <UnthemedQuoteResume {...props} />);

const QuoteResume = (props: RouteComponentProps) => {
  return <ConnectedQuoteResume {...props} />;
};

export default QuoteResume;
