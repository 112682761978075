import React from 'react';
import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const generatePrefix = () =>
  process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : '';

// Bugsnag & error boundary settings
Bugsnag.start({
  apiKey: '7c1d7c675e35fe4f145da1e324418fb5',
  plugins: [new BugsnagPluginReact()],
  // enabledReleaseStages: ['production'],
  endpoints: {
    notify: generatePrefix() + '/bugsnag/notify',
    sessions: generatePrefix() + '/bugsnag/sessions',
  },
});

export const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React);
