import { graphql } from '../gql';

export const RELAY_QUOTES = graphql(`
  query relayQuotes($token: String!, $cursor: String, $first: Int = 100) {
    relayQuotes(first: $first, after: $cursor, token: $token) {
      edges {
        node {
          id
          uniqueID
          friendlyId
          status
          dateAdded
          businessLine {
            id
            displayName
          }
          structuredData {
            businessLegalName
            businessName
            businessTradeName
            contactName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);
