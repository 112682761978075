import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  IconButton,
  Modal,
  Stack,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import SettingsIcon from '@mui/icons-material/Settings';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
// components
import ExtensionsButton from '../components/Extensions';
import { CreateQualifyingQuestion } from '../components/CreateQualifyingQuestion';
import { RequiredToggle } from '../components/FormRequired';
import { LeaveDirtyFormConfirmation } from '../components/LeaveDirtyFormConfirmation';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderForm } from '../classes';

// ----------------------------------------------------------------------

const ModalStyle = styled('div')(({ theme }) => ({
  maxHeight: '90vh',
  maxWidth: '50rem',
  width: '100%',
  height: '90vh',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  padding: theme.spacing(3),
  position: 'relative',
  overflow: 'auto',
}));

// ----------------------------------------------------------------------

function TabPanel(props: $TSFixMe) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: $TSFixMe) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// ----------------------------------------------------------------------

interface FormSettingsProps {
  form: ProgramBuilderForm;
}
const FormSettings = ({ form }: FormSettingsProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState(0); // what is this?
  const [qualifyingQuestionDirty, setQualifyingQuestionDirty] =
    useState<boolean>(false); // is the qualifying question unsaved?
  const [leaveDirtyFormConfirmationOpen, setLeaveDirtyFormConfirmationOpen] =
    useState<boolean>(false);
  const { policyExtensions } = form;

  // handlers
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (_event: $TSFixMe, newValue: $TSFixMe) => {
    setValue(newValue);
  };

  return (
    <>
      <IconButton
        aria-label='form settings'
        size='small'
        onClick={() => setOpen(true)}
      >
        {/*
        // @ts-expect-error */}
        <SettingsIcon size='small' />
      </IconButton>

      <Modal
        data-testid='settings-modal'
        open={open}
        onClose={handleClose}
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
        BackdropComponent={() => (
          <Backdrop
            data-testid='settings-modal-backdrop'
            open={true}
            onClick={() => {
              if (qualifyingQuestionDirty) {
                setLeaveDirtyFormConfirmationOpen(true);
              } else {
                handleClose();
              }
            }}
          />
        )}
        onBackdropClick={() => {
          if (qualifyingQuestionDirty) {
            setLeaveDirtyFormConfirmationOpen(true);
          } else {
            handleClose();
          }
        }}
      >
        <ModalStyle>
          <Stack mb={4} direction='row' spacing={1.5}>
            {/*
              // @ts-expect-error */}
            <SettingsIcon size='small' color='primary' />
            <Typography variant='h6' color='textSecondary'>
              Form Settings
            </Typography>
          </Stack>
          <IconButton
            sx={{
              position: 'absolute',
              right: theme.spacing(3),
              top: theme.spacing(3),
              padding: 0,
            }}
            onClick={() => handleClose()}
            size='large'
          >
            <ClearIcon />
          </IconButton>

          <RequiredToggle form={form} />

          <Box sx={{ width: '100%', mt: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='Settings tabs'
              >
                <Tab label='Qualifying Question' {...a11yProps(0)} />
                <Tab label='Extensions' {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CreateQualifyingQuestion
                form={form}
                setQualifyingQuestionDirty={setQualifyingQuestionDirty}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ExtensionsButton
                form={form}
                policyExtensions={policyExtensions}
              />
            </TabPanel>
          </Box>
          <LeaveDirtyFormConfirmation
            open={leaveDirtyFormConfirmationOpen}
            setOpen={setLeaveDirtyFormConfirmationOpen}
            handleModalClose={() => setOpen(false)}
            warningText='The qualifying question contains unsaved changes. Are you sure?'
          />
        </ModalStyle>
      </Modal>
    </>
  );
};

export default FormSettings;
