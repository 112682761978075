import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { Button } from '@mui/material';
// components
import { FormModal } from '../FormModal';
import QuestionInstanceForm from './QuestionInstanceForm';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderForm } from '../../classes';

interface CreateQuestionInstanceProps {
  form: ProgramBuilderForm;
}
const CreateQuestionInstance = ({ form }: CreateQuestionInstanceProps) => {
  const [open, setOpen] = useState(false);

  // handler
  const handleClose = () => {
    //console.log('Calling handleClose (CreateQuestionInstance)');
    setOpen(false);
    // dispatch(programBuilderWizard.actions.clearPendingQuestion());
  };

  return (
    <>
      <FormModal
        open={open}
        handleClose={handleClose}
        FormComponent={(props: $TSFixMe) => (
          <QuestionInstanceForm
            parentForm={form}
            isEditing={false}
            {...props}
          />
        )}
        title='Create Custom Question'
      />
      <Button
        data-testid='create-new-question-instance'
        size='small'
        variant='contained'
        onClick={() => setOpen(true)}
        endIcon={<Icon icon={plusFill} />}
      >
        Create Question
      </Button>
    </>
  );
};

export default CreateQuestionInstance;
