import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { BusinessType } from '../../BusinessType';
import { StructuredQuoteData } from './StructuredQuoteData';
import { Signature } from './Signature';
import { Language } from '../../classes/Language';
import { BackendCompletedForm } from './BackendCompletedForm';
import { UnifiedAnswerInstance } from '../../classes';
import { filterDuplicateLocations } from '../../../components/common/QuotePdf/components/LocationsPage';
import type { QuoteStatus } from '../types';

interface QuoteInput {
  id?: string;
  status: QuoteStatus;
  businessLine: BusinessType;
  uniqueId: string;
  friendlyId?: string;
  dateAdded?: string;
  structuredData: StructuredQuoteData;
  signature?: Signature;
  language: Language;
  completedForms?: Array<BackendCompletedForm>;
  additionalInformation?: string;
  producerId?: string | null;
  isBound?: boolean;
}

/**
 * A Backend Quote
 */
export class Quote {
  id?: string;
  status: QuoteStatus;
  businessLine: BusinessType;
  uniqueId: string;
  friendlyId: string;
  dateAdded?: string;
  structuredData: StructuredQuoteData;
  signature?: Signature;
  language: Language;
  completedForms: Array<BackendCompletedForm>;
  additionalInformation: string;
  producerId: string;
  isBound: boolean;

  constructor(input: QuoteInput) {
    const {
      id,
      status,
      businessLine,
      uniqueId,
      dateAdded,
      structuredData,
      signature,
      language,
      completedForms,
      additionalInformation,
      friendlyId,
      producerId,
      isBound,
    } = input;
    this.id = id;
    this.status = status;
    this.businessLine = businessLine;
    this.uniqueId = uniqueId;
    this.dateAdded = dateAdded;
    this.structuredData = structuredData;
    this.signature = signature;
    this.language = language;
    this.completedForms = completedForms
      ? completedForms.sort((form1, form2) => {
          if (!form1.policy && form2.policy) {
            return 1;
          }
          if (form1.policy && !form2.policy) {
            return -1;
          }
          if (!form1.policy && !form2.policy) {
            return 0;
          }
          // @ts-expect-error
          return form1.policy.displayName.localeCompare(
            // @ts-expect-error
            form2.policy.displayName,
          );
        })
      : [];
    this.additionalInformation = additionalInformation || '';
    this.friendlyId = friendlyId || '';
    this.producerId = producerId || '';
    this.isBound = isBound || false;
  }

  /**
   * Return all of the answered location questions present on any form
   */
  allAnsweredLocationAnswers() {
    return this.completedForms.reduce(
      (allLocations: Array<UnifiedAnswerInstance>, form) => {
        return [...allLocations, ...form.allAnsweredLocationAnswers()];
      },
      [],
    );
  }

  /**
   * Return all of the *unique* answered location questions present in the forms
   */
  allUniqueAnsweredLocationAnswers({
    includedApiNames,
  }: { includedApiNames?: Array<string> } = {}) {
    const allAnsweredLocationAnswers = this.allAnsweredLocationAnswers();
    let filteredLocations = filterDuplicateLocations(
      allAnsweredLocationAnswers,
    );
    if (Array.isArray(includedApiNames)) {
      filteredLocations = filteredLocations.filter((potentialLocation) =>
        includedApiNames.includes(potentialLocation.apiName),
      );
    }
    return filteredLocations;
  }

  /**
   * Whether or not the quote is in a completed state (complete or renewed )
   */
  isComplete() {
    return (
      this.status === 'COMPLETE' ||
      this.status === 'RENEWED' ||
      this.status === 'CONFIRMED'
    );
  }

  /**
   * Whether this is a saved quote (saved by the user)
   */
  isSaved() {
    const savedStatuses: Array<QuoteStatus> = [
      'INCOMPLETE',
      'INCOMPLETE_RENEWAL',
      'INCOMPLETE_CONFIRMATION',
    ];
    return savedStatuses.includes(this.status);
  }

  /**
   * Whether the quote is on the verge of being renewed (status is quote_email_sent or renewal_link_generated or renewal_in_progress)
   */
  isReadyToRenew() {
    return (
      this.status === 'RENEWAL_EMAIL_SENT' ||
      this.status === 'RENEWAL_LINK_GENERATED' ||
      this.status === 'RENEWAL_IN_PROGRESS'
    );
  }

  getDisplayId() {
    return this.friendlyId || this.uniqueId;
  }

  /**
   * Static functions
   */
  static generateFromBackendResponse(backendInput: $TSFixMe) {
    //console.log('In this.generateFromBackendResponse with input', backendInput);
    return new Quote({
      ...backendInput,
      uniqueId: backendInput.uniqueID,
      status:
        backendInput.status ||
        backendInput.QuoteStatus ||
        backendInput.ArchivedQuoteStatus,
      structuredData: StructuredQuoteData.generateFromBackendResponse(
        backendInput.structuredData,
      ),
      signature: backendInput.signature
        ? Signature.generateFromBackendResponse(backendInput.signature)
        : undefined,
      language: Language.createFromBackendResponse(backendInput.language),
      completedForms: backendInput.completedForms
        ? backendInput.completedForms.map((completedFormResponse: $TSFixMe) =>
            BackendCompletedForm.generateFromBackendResponse(
              completedFormResponse,
            ),
          )
        : [],
    });
  }
}
