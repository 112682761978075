import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { TOGGLE_BIND_STATUS } from '../../../../queries';
// import Button from '@mui/material/Button';
import { Quote } from '../../../../Typescript/backend/classes';
import Label from '../../components/Label';
import {
  useAllowByAdminPermissions,
  useUsingVagoSettings,
} from '../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
// import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
// import Bugsnag from '@bugsnag/browser';
// import { errorify } from '@calefy-inc/utility';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  styled,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

interface QuoteBoundStatusProps {
  quote: Quote;
}
export const QuoteBoundStatus = ({ quote }: QuoteBoundStatusProps) => {
  const { token } = useAuth();
  const hasAdminPermissions = useAllowByAdminPermissions();
  const onVago = useUsingVagoSettings();
  const [toggleBoundStatus, { loading, error, data }] = useMutation(
    TOGGLE_BIND_STATUS,
    {
      refetchQueries: ['Quote'],
      update: (cache, { data }) => {
        if (!data?.toggleBindStatus?.quote) {
          return;
        }
        const id = cache.identify(data?.toggleBindStatus?.quote);
        cache.modify({
          id,
          fields: {
            isBound: () => data?.toggleBindStatus?.quote?.isBound,
          },
        });
      },
    },
  );
  const [isBound, setIsBound] = useState<Quote['isBound']>(quote.isBound);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [showBindButton, setShowBindButton] = useState<boolean>(!quote.isBound);
  const { enqueueSnackbar } = useSnackbar();

  // update the bound status
  useEffect(() => {
    if (data?.toggleBindStatus?.quote) {
      setIsBound(data.toggleBindStatus.quote.isBound);
    }
  }, [data]);

  // update the error message
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [error]);

  // update whether or not we should show the button to bind / unbind the quote
  useEffect(() => {
    let show = false;
    if (hasAdminPermissions === true) {
      show = true;
    } else if (quote.isBound === false) {
      show = true;
    }
    setShowBindButton(show);
  }, [hasAdminPermissions, quote]);

  // whenever we get a response hide the button until there's a chance to set the correct state
  useEffect(() => {
    if (data) {
      setShowBindButton(false);
    }
  }, [data]);

  if (!onVago) {
    return null;
  }
  return (
    <BoundStatusContainer>
      <Label color={isBound ? 'success' : 'warning'}>
        {isBound ? 'BOUND' : 'NOT BOUND'}
      </Label>
      {showBindButton ? (
        <LoadingButton
          loading={loading}
          disabled={!token}
          onClick={() => setConfirmationOpen(true)}
        >
          {isBound ? 'Unbind' : 'Bind'}
        </LoadingButton>
      ) : null}
      <MarkQuoteBoundConfirmationDialog
        quote={quote}
        open={confirmationOpen}
        onCancel={() => setConfirmationOpen(false)}
        onConfirm={() => {
          toggleBoundStatus({ variables: { token, uniqueId: quote.uniqueId } });
          setConfirmationOpen(false);
        }}
      />
    </BoundStatusContainer>
  );
};

const BoundStatusContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  };
});

interface MarkQuoteBoundConfirmationDialogProps {
  quote: Quote;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
const MarkQuoteBoundConfirmationDialog = ({
  quote,
  open,
  onConfirm,
  onCancel,
}: MarkQuoteBoundConfirmationDialogProps) => {
  const [typedValue, setTypedValue] = useState<string>('');
  const [ok, setOk] = useState<boolean>(false);

  useEffect(() => {
    setOk(typedValue === quote.getDisplayId());
  }, [typedValue, quote]);

  return (
    <Dialog open={open}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        {quote.isBound ? (
          <Typography>
            Are you sure? This will mark the quote as being unbound, allowing
            further edits.
          </Typography>
        ) : (
          <Typography>
            Are you sure? This action is <strong>irreversible</strong>, and once
            the quote has been marked as bound no further edits will be
            possible.{' '}
          </Typography>
        )}

        <Typography gutterBottom>
          In order to continue, please type in the ID of the application:
        </Typography>

        <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
          {quote.getDisplayId()}
        </Typography>
        <ConfirmationStatusContainer>
          <TextField
            fullWidth
            label='Application ID'
            value={typedValue}
            onChange={(e) => setTypedValue(e.target.value)}
          />
          {ok ? (
            <Tooltip title='Correct'>
              <CorrectIcon />
            </Tooltip>
          ) : (
            <Tooltip title='Incorrect'>
              <IncorrectIcon />
            </Tooltip>
          )}
        </ConfirmationStatusContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setTypedValue('');
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!ok}
          onClick={() => {
            setTypedValue('');
            onConfirm();
          }}
        >
          Mark Quote as {quote.isBound ? 'Unbound' : 'Bound'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CorrectIcon = styled(CheckIcon)(({ theme }) => {
  return { color: theme.palette.success.main };
});
const IncorrectIcon = styled(CloseIcon)(({ theme }) => {
  return { color: theme.palette.error.main };
});

const ConfirmationStatusContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
  };
});
