import { styled } from '@mui/material';

export const StyledTable = styled('table')(({ theme }) => {
  return {
    borderCollapse: 'collapse',
    borderSpacing: theme.spacing(1),
    width: '100%',
  };
});

export const StyledTr = styled('tr')(() => {
  return {
    textAlign: 'start',
  };
});
export const StyledTh = styled('th')(({ theme }) => {
  return {
    padding: theme.spacing(0, 2),
  };
});
export const StyledThead = styled('thead')(({ theme }) => {
  return {
    '& th': {
      borderBottom: `solid ${theme.spacing(0.25)} black`,
    },
  };
});
export const StyledTbody = styled('tbody')();
export const StyledTd = styled('td')(({ theme }) => {
  return {
    padding: theme.spacing(0, 2),
    ':nth-of-type(1)': {
      // peril name
      width: '30%',
    },
    ':nth-of-type(2)': {
      // limits
      width: '20%',
    },
    ':nth-of-type(3)': {
      // rate
      width: '15%',
    },
    ':nth-of-type(4)': {
      // premium
      width: '20%',
    },
    ':nth-of-type(5)': {
      // override premium?
      width: '15%',
      textAlign: 'center',
    },
  };
});
