import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { QuoteComponentValidationFunctionReturn } from '../types';
import { QuoteWizardAnswerInstance } from '../../../QuoteWizard/classes';
import { ProgramBuilderQuestionInstance } from '../../../FormManager/classes';
import { UnifiedAnswerInstance } from '../../../../Typescript/classes';

/**
 * Verify that an alleged logic answer in the redux format is a logic question
 * @param answer - The answer to be checked
 * @return - nothing! (Throw an error if it is not in the correct format)
 */
export const verifyReduxLogicAnswer = (answer: QuoteWizardAnswerInstance) => {
  if (!answer.questionInstance) {
    throw new Error(
      `Answer has no "questionInstance" attribute! ${JSON.stringify(
        answer,
        null,
        4,
      )}`,
    );
  }
  if (!answer.questionInstance.component) {
    throw new Error(
      `Answer's questionInstance malformed - no 'component' attribute found! ${JSON.stringify(
        answer,
        null,
        4,
      )}`,
    );
  }
  if (answer.questionInstance.component !== 'logic') {
    throw new Error(
      `Passed in answer is not a logic answer! ${JSON.stringify(
        answer,
        null,
        4,
      )}`,
    );
  }
};
/**
 * Given the *answer* to a logic question in the Redux store format, determine whether the condition is true
 * @param  answer - The answer to a logic question
 * @return  - Whether or not the condition is true (<=> the other subquestions should be displayed)
 */
export function isLogicQuestionConditionTrue(
  answer: QuoteWizardAnswerInstance,
): boolean {
  verifyReduxLogicAnswer(answer);

  const operatorMapping = {
    equals: (value: $TSFixMe, condition: $TSFixMe) => {
      return value === condition;
    },
    greater: (value: $TSFixMe, condition: $TSFixMe) => {
      return value >= condition;
    },
    lesser: (value: $TSFixMe, condition: $TSFixMe) => {
      return value <= condition;
    },
    contains: (value: $TSFixMe, condition: $TSFixMe) => {
      if (typeof value !== 'string' || value === 'N/A') {
        return false;
      }
      return value.includes(condition);
    },
  };
  const propsBlob = answer.questionInstance.propsBlob;
  const { not, operator, condition } = propsBlob;
  const conditionAnswer = answer.subAnswers[0];

  // @ts-expect-error
  const interimResult = operatorMapping[operator](
    conditionAnswer.value,
    condition,
  );
  const result = not ? !interimResult : interimResult;

  return result;
}
/**
 * Given the *answer* to a logic question from the backend format, determine whether the condition is true
 * @param  answer - The answer to a logic question
 * @return  - Whether or not the condition is true (<=> the other subquestions should be displayed)
 */
export function isBackendLogicQuestionConditionTrue(
  answer: UnifiedAnswerInstance,
): boolean {
  const operatorMapping = {
    equals: (value: $TSFixMe, condition: $TSFixMe) => {
      return value === condition;
    },
    greater: (value: $TSFixMe, condition: $TSFixMe) => {
      return value >= condition;
    },
    lesser: (value: $TSFixMe, condition: $TSFixMe) => {
      return value <= condition;
    },
    contains: (value: $TSFixMe, condition: $TSFixMe) => {
      if (typeof value !== 'string' || value === 'N/A') {
        return false;
      }
      return value.includes(condition);
    },
  };
  // @ts-expect-error
  const { not, operator, condition } = answer.propsBlob;
  const conditionAnswer = answer.subAnswers[0];

  // @ts-expect-error
  const interimResult = operatorMapping[operator](
    conditionAnswer.value,
    condition,
  );
  const result = not ? !interimResult : interimResult;

  return result;
}

/**
 * Validate that a Logic question created in the ProgramBuilder is correct
 */
export function validateLogicQuestion(
  questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  const errorMessages = [];
  if (
    !questionInstance.subQuestions ||
    questionInstance.subQuestions.length < 2
  ) {
    errorMessages.push(
      `A conditional question must include at least two subquestions; you included ${
        !questionInstance.subQuestions ||
        questionInstance.subQuestions.length === 0
          ? 'none'
          : questionInstance.subQuestions.length
      } for Conditional question with name "${questionInstance.generateDisplayName()}".`,
    );
  }

  if (!questionInstance.propsBlob) {
    errorMessages.push(
      `Conditional question with name "${questionInstance.generateDisplayName()}" is missing some required attributes.`,
    );
  } else {
    const { operator, condition } = questionInstance.propsBlob;

    if (!operator) {
      errorMessages.push(
        `Conditional question with name "${questionInstance.generateDisplayName()}" is missing the operation.`,
      );
    } else {
      const validOperators: Array<string> = [
        'equals',
        'greater',
        'lesser',
        'contains',
      ];
      if (!validOperators.includes(operator)) {
        errorMessages.push(
          `Conditional question with name "${questionInstance.generateDisplayName()}" has an invalid operation.`,
        );
      }
    }
    if (!condition) {
      errorMessages.push(
        `Conditional question with name "${questionInstance.generateDisplayName()}" is missing the condition.`,
      );
    }
  }

  // check that the primary question does not itself have subquestions
  if (
    questionInstance &&
    questionInstance.subQuestions &&
    questionInstance.subQuestions.length > 0
  ) {
    const primaryQuestion = questionInstance.subQuestions[0];
    if (
      primaryQuestion &&
      primaryQuestion.subQuestions &&
      primaryQuestion.subQuestions.length > 0
    ) {
      errorMessages.push(
        `Conditional question with name '${questionInstance.generateDisplayName()}' has an invalid primary question (name: '${primaryQuestion.generateDisplayName()}'): primary questions may not contain subquestions.`,
      );
    }
  }

  return errorMessages;
}
