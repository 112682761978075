import { LoadingButton } from '@mui/lab';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const ClientSummary = () => {
  const downloadCSV = () => {
    // Create a Blob containing the CSV data
    const blob = new Blob(
      [
        `Company Name,Client Name,Mailing Address,Email,Phone,Revenue,Number of Locations,GL Limit,TIV
    Weyland-Yutani Corporation,Captain Ripley,"42 Cosmic Lane, Nebula City Canada, A1B 2C3",captain.ripley@weyland-yutani.com,(555) 123-4567,7500000,3,5000000,3500000
    Acme Corporation,Mr. Coyote,"23 Quantum Street, Nova Town Canada, X4Y 5Z6",mr.coyote@acmecorp.com,(555) 234-5678,4500000,5,1000000,2500000
    Tyrell Corporation,Deckard,"88 Celestial Avenue, Star City Canada, M7N 8P9",deckard@tyrell.com,(555) 345-6789,6000000,4,2000000,4500000
    Stark Industries,Tony Stark,"77 Galactic Road, Cosmos Town Canada, L2K 9J3",tony.stark@starkindustries.com,(555) 456-7890,3000000,2,5000000,1500000
    Wayne Enterprises,Bruce Wayne,"99 Space Avenue, Starlight City Canada, R3S 7T2",bruce.wayne@wayneenterprises.com,(555) 567-8901,8000000,6,3000000,5500000
    Oscorp Industries,Norman Osborn,"55 Nebula Street, Cosmos Town Canada, T1X 4Y6",norman.osborn@oscorp.com,(555) 678-9012,4000000,3,7000000,2000000
    Umbrella Corporation,Alice,"123 Umbrella Lane, Raccoon City Canada, M4B 6H3",alice@umbrellacorp.com,(555) 789-0123,9000000,7,8000000,7000000
    LexCorp,Lex Luthor,"456 Lex Avenue, Metropolis Canada, V8R 2Z9",lex.luthor@lexcorp.com,(555) 890-1234,12000000,9,10000000,9000000
    InGen Corporation,Dr. Grant,"789 Dino Street, Isla Nublar Canada, Y0U 2R1",dr.grant@ingen.com,(555) 901-2345,1500000,1,2000000,1800000
    Buy N Large,Wall-E,"42 Buy N Large Boulevard, Trash Compactor City Canada, X1Y 2Z3",wall-e@buynlarge.com,(555) 012-3456,2000000,4,3000000,2500000
    Dunder Mifflin Paper Company,Michael Scott,"23 Scranton Street, Scranton Canada, V2T 5R6",michael.scott@dundermifflin.com,(555) 123-4567,3500000,2,1500000,1200000
    Cyberdyne Systems,John Connor,"88 Future Lane, Skynet City Canada, M3R 6T7",john.connor@cyberdyne.com,(555) 234-5678,7000000,5,4000000,3500000
    Monsters Inc.,Sully,"77 Monster Alley, Monstropolis Canada, K8L 3M2",sully@monstersinc.com,(555) 345-6789,5500000,3,6000000,5000000
    Zorg Industries,Mr. Zorg,"99 Zorg Street, Evil Lair City Canada, R1V 9P4",mr.zorg@zorgindustries.com,(555) 456-7890,10000000,7,12000000,10000000
    Soylent Corporation,Charlton Heston,"123 Soylent Avenue, Future Earth Canada, L3F 7I8",charlton.heston@soylentcorp.com,(555) 567-8901,8000000,6,5000000,7500000
    Omni Consumer Products (OCP),RoboCop,"456 Delta City Boulevard, Detroit Canada, S2K 8R9",robocop@ocp.com,(555) 678-9012,9500000,8,15000000,12000000
    Spectre International,James Bond,"789 Spy Lane, MI6 City Canada, T9X 1S4",james.bond@spectre.com,(555) 789-0123,11000000,5,7000000,9000000
    Bluth Company,Michael Bluth,"42 Banana Street, Model Home City Canada, E6B 3D2",michael.bluth@bluthcompany.com,(555) 901-2345,3000000,2,2000000,1800000
    Rekall Inc.,Douglas Quaid,"23 Recall Lane, Mars Colony Canada, N4L 6J7",douglas.quaid@rekall.com,(555) 012-3456,1500000,1,800000,700000
    Aperture Science,Chell,"88 Portal Avenue, Test Chamber City Canada, V2X 5T3",chell@becausewe.can,(555) 012-3457,5500000,2,12000000,7500000`,
      ],
      { type: 'text/csv' },
    );

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';

    // Append the link to the body and programmatically click it
    document.body.appendChild(link);
    link.click();

    // Clean up: remove the link element
    document.body.removeChild(link);
  };

  return (
    <LoadingButton
      loadingPosition='start'
      startIcon={<FileDownloadOutlinedIcon />}
      onClick={downloadCSV}
    >
      Client Summary
    </LoadingButton>
  );
};
