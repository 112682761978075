import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { TextField, Select, Form } from '@calefy-inc/informedMaterial';
import { FormValues } from 'informed';
import { connect } from 'react-redux';
import { formStore } from '../../../../store';

import ComponentRegistry from '../../../common/QuoteComponents';
import componentRegistry from '../../../common/QuoteComponents';

import {
  /* extractPropProperties, */
  /* generateNewQualifyingQuestionInstanceFromForm, */
  questionInstanceFormDirty,
  validateApiName,
  /* sanitizeForApiName, */
} from '../CreateQuestionInstance/QuestionInstanceForm/utility';
import { LanguageFields } from '../CreateQuestionInstance/QuestionInstanceForm/LanguageFields';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Language } from '../../../../Typescript/classes';
import {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../../classes';
import { Dispatch, StoreState } from '../../../../store';

// Array list of components
const quoteComponentTypes = ComponentRegistry.getComponentTypes().map(
  ({ type, typeLabel }) => ({ value: type, label: typeLabel }),
);

// Retrieve required component (yesnotoggle)
const requiredComponent: Array<$TSFixMe> = [];
// TODO this is a really, really bad way to do this
requiredComponent.push(quoteComponentTypes[5]);

function validateExists(value: $TSFixMe) {
  if (!value) {
    return 'Field cannot be blank';
  }
}

const QualifyingQuestionWithStyles = withStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}`,
  },
  selectField: {
    margin: `${theme.spacing(2)} 0`,
    '& > label': {
      ...theme.typography.label,
      marginBottom: theme.spacing(1.5),
    },
  },
  textField: {
    margin: `${theme.spacing(2)} 0`,
  },
  textarea: {
    maxWidth: '100%',
    margin: `${theme.spacing(4)} 0`,
  },
  buttonContainer: {
    alignSelf: 'flex-end',
  },
}))((props: $TSFixMe) => <QualifyingQuestionDisplay {...props} />);

interface QualifyingQuestionDisplayProps {
  qualifyingQuestion: ProgramBuilderQuestionInstance | null;
  /* onQualifyingQuestionCreated: $TSFixMe; */
  /* onQualifyingQuestionEdited: $TSFixMe; */
  setQualifyingQuestion: (
    qualifyingQuestion: ProgramBuilderQuestionInstance,
  ) => void;
  parentForm: ProgramBuilderForm;
  isEditing: boolean;
  newQualifyingId: $TSFixMe;
  classes: $TSFixMe;
  setQualifyingQuestionDirty: $TSFixMe;
  languages: Array<Language>;
  [k: string]: $TSFixMe;
}
export function QualifyingQuestionDisplay({
  qualifyingQuestion,
  /* onQualifyingQuestionCreated, */
  /* onQualifyingQuestionEdited, */
  setQualifyingQuestion,
  parentForm,
  isEditing,
  newQualifyingId,
  classes = {},
  setQualifyingQuestionDirty,
  languages,
  // @ts-expect-error
  ...otherProps
}: QualifyingQuestionDisplayProps) {
  const { enqueueSnackbar } = useSnackbar();
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataType, setDataType] = useState<string>(
    // @ts-expect-error
    // eslint-disable-next-line react-hooks/exhaustive-deps
    componentRegistry.getComponentDataType() || 'string',
  );
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...(qualifyingQuestion ? qualifyingQuestion.convertToFormState() : {}),
    component: requiredComponent[0].value,
    dataType,
  });
  const [currentFormValues, setCurrentFormValues] = useState<FormValues>({});
  const [newQualifyingQuestion, setNewQualifyingQuestion] =
    useState<ProgramBuilderQuestionInstance | null>(null);

  /*console.log( */
  /*   'ÈèQualifyingQuestionFormÈ - qualifyingQuestion', */
  /*   qualifyingQuestion, */
  /*   parentForm, */
  /* ); */
  // create the initial form values
  useEffect(() => {
    if (!qualifyingQuestion) {
      setInitialFormValues({ component: requiredComponent[0].value, dataType });
      return;
    }
    setInitialFormValues(qualifyingQuestion.convertToFormState());
    /* const propBlobAsProperties = extractPropProperties( */
    /*   qualifyingQuestion.propsBlob, */
    /* ); */
    /* setInitialFormValues({ */
    /*   ...qualifyingQuestion, */
    /*   ...propBlobAsProperties, */
    /* }); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualifyingQuestion, setInitialFormValues]);

  // create the new qualifying question from the form values, &c.
  useEffect(() => {
    const generatedQuestion = ProgramBuilderQuestionInstance.generateFromValues(
      {
        formValues: currentFormValues,
        languages,
        oldQuestion: qualifyingQuestion,
        otherAttributes: {
          dataType,
          ancillary: {
            form: parentForm,
          },
          clientId:
            qualifyingQuestion && qualifyingQuestion.clientId
              ? qualifyingQuestion.clientId
              : newQualifyingId,
        },
      },
    );
    const gatherSubQuestions = ComponentRegistry.getGatherSubQuestionsFunction(
      currentFormValues.component as string,
    );
    if (!gatherSubQuestions) {
      /*console.log('QualifyingQuestion: No gatherSubQuestions found'); */
    }
    const amendedQuestion = gatherSubQuestions
      ? gatherSubQuestions(currentFormValues, generatedQuestion)
      : generatedQuestion;

    /* const newQuestion = generateNewQualifyingQuestionInstanceFromForm({ */
    /*   oldQuestion: qualifyingQuestion, */
    /*   formValues: currentFormValues, */
    /*   dataType, */
    /*   form: parentForm, */
    /*   newQualifyingId, */
    /* }); */
    /*console.log( */
    /*   'QualifyingQuestion: About to set qualifying question to amendedQuestion', */
    /*   amendedQuestion, */
    /* ); */
    setNewQualifyingQuestion(amendedQuestion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    qualifyingQuestion,
    currentFormValues,
    dataType,
    parentForm,
    newQualifyingId,
  ]);

  // set whether the form is dirty or not
  useEffect(() => {
    const changesPresent = questionInstanceFormDirty({
      initialFormValues,
      currentFormValues,
      oldQuestion: qualifyingQuestion,
      newQuestion: newQualifyingQuestion,
    });
    setQualifyingQuestionDirty(changesPresent);
  }, [
    qualifyingQuestion,
    newQualifyingQuestion,
    initialFormValues,
    currentFormValues,
    setQualifyingQuestionDirty,
  ]);

  if (isEditing && !qualifyingQuestion) {
    return null;
  }
  if (initialFormValues === undefined) {
    return null;
  }
  return (
    <Form
      className={classes.form}
      data-testid='qualifying-question-form'
      autoComplete='off'
      noValidate
      initialValues={initialFormValues}
      onChange={(formState: $TSFixMe) => {
        setCurrentFormValues({ ...formState.values });
      }}
      onSubmit={(_formState: $TSFixMe) => {
        if (newQualifyingQuestion) {
          setQualifyingQuestion(newQualifyingQuestion);
        }
        /* if (isEditing) { */
        /*   onQualifyingQuestionEdited({ */
        /*     qualifyingQuestion: newQualifyingQuestion, */
        /*   }); */
        /* } else { */
        /*   onQualifyingQuestionCreated({ */
        /*     qualifyingQuestion: newQualifyingQuestion, */
        /*   }); */
        /* } */

        enqueueSnackbar('Update form to save changes', {
          variant: 'info',
        });
      }}
    >
      {/*
        // @ts-expect-error */}
      {({ formApi }) => {
        return (
          <>
            <Select
              field='component'
              label='Question Input Type'
              inputId='component-select'
              isClearable={false}
              required
              validate={validateExists}
              className={classes.selectField}
              classes={classes}
              options={requiredComponent}
              placeholder='Select the type of UI input displayed to the user'
              containerTestId='component-selection'
              value={requiredComponent[0].value}
              disabled
            />
            {languages.map((language) => {
              return (
                <LanguageFields
                  languages={languages}
                  language={language}
                  classes={classes}
                />
              );
            })}
            <TextField
              className={classes.textField}
              classes={classes}
              label='Enter the private name of the question. This will be used internally to retrieve client answer data. It will not be shown to the user.'
              field='apiName'
              required
              validate={validateApiName}
              id='name'
              variant='standard'
            />
            <div className={classes.buttonContainer}>
              <Button variant='contained' type='submit'>
                Update Question
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

function mapStateToProps(state: StoreState) {
  const languages = state.programBuilder.languages;
  return { languages };
}

function mapDispatchToProps(dispatch: Dispatch) {
  /* function onQualifyingQuestionCreated({ qualifyingQuestion }: $TSFixMe) { */
  /*   return dispatch( */
  /*     formStore.actions.addQualifyingQuestion({ qualifyingQuestion }), */
  /*   ); */
  /* } */

  /* function onQualifyingQuestionEdited({ qualifyingQuestion }: $TSFixMe) { */
  /*   return dispatch( */
  /*     formStore.actions.updateQualifyingQuestion({ qualifyingQuestion }), */
  /*   ); */
  /* } */

  function setQualifyingQuestion(
    qualifyingQuestion: ProgramBuilderQuestionInstance,
  ) {
    dispatch(formStore.actions.setQualifyingQuestion({ qualifyingQuestion }));
  }

  return {
    setQualifyingQuestion,
    /* onQualifyingQuestionCreated, */
    /* onQualifyingQuestionEdited, */
  };
}

export const QualifyingQuestionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default QualifyingQuestionConnect((mappedState: $TSFixMe) => {
  return <QualifyingQuestionWithStyles {...mappedState} />;
});
